import { createContext } from "react";
import type { CustomFieldsResponseItem } from "../webcomponent/responses";
import findBaseUrl from "../helpers/baseUrl";

interface IBaseContext {
  isLoadingCustomFields: boolean,
  customFields: CustomFieldsResponseItem[],
  correlationId: string | null,
  cuttingFormCode: string,
  basketUrl: string,
  languagecode: string,
  designheaderid: string,
  company: string | null,
  shopCode: string | null,
  visitorId: string | null,
  baseapiurl: string,
  uiapiurl: string,
  itemno: string,
  onclicksubmit?: any,
  onclicksave?: any,
}

const INITIAL_CUTTING_CODE = 'K4211SSRNSIMFCS';

const isLoadingCustomFields = false;
const customFields: CustomFieldsResponseItem[] = [];

const wc = document.querySelector('jako-configurator');

let correlationId: string | null = '111';
let company: string | null = 'JAKO AG';
let shopCode: string | null = 'B2C';
let visitorId: string | null = '111';
let cuttingFormCode: string = INITIAL_CUTTING_CODE;
let basketUrl: string = 'https://jakob2b-dev.dc-test.de/b2b/de/3d_configurator/?step=basket';
let languagecode: string = 'DE';
let designheaderid: string = '0';
let baseapiurl: string = 'https://jako-api.dc-test.de/public';
let uiapiurl: string = findBaseUrl() + '/api/v1';
let itemno: string = '55';
let onclicksubmit;
let onclicksave;

if (wc) {
  correlationId = wc.getAttribute('correlationId') || correlationId;
  company = wc.getAttribute('company') || company;
  shopCode = wc.getAttribute('shopCode') || shopCode;
  visitorId = wc.getAttribute('visitorId') || visitorId;
  cuttingFormCode = wc.getAttribute('cuttingFormCode') || cuttingFormCode;
  basketUrl = wc.getAttribute('basketurl') || basketUrl;
  languagecode = wc.getAttribute('languagecode') || languagecode;
  designheaderid = wc.getAttribute('designheaderid') || designheaderid;
  baseapiurl = wc.getAttribute('baseapiurl') || baseapiurl;
  uiapiurl = wc.getAttribute('uiapiurl') || uiapiurl;
  itemno = wc.getAttribute('itemno') || itemno;
  const wcAny = wc as any;
  onclicksubmit = wcAny.onclicksubmit as any;
  onclicksave = wcAny.onclicksave as any;
} else {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('testCutting');
  cuttingFormCode = myParam ? myParam : INITIAL_CUTTING_CODE.slice(0, 5) + 'BE' + INITIAL_CUTTING_CODE.slice(5);
}

const IS_IOS =
  /^(iPad|iPhone|iPod)/.test(window.navigator.platform) ||
  (/^Mac/.test(window.navigator.platform) && window.navigator.maxTouchPoints > 1);
if (IS_IOS) {
  window.createImageBitmap = undefined as any;
}

export const BaseContext = createContext<IBaseContext>({
  isLoadingCustomFields,
  customFields,
  correlationId,
  visitorId,
  company,
  shopCode,
  cuttingFormCode,
  basketUrl,
  languagecode,
  designheaderid,
  baseapiurl,
  uiapiurl,
  itemno,
  onclicksubmit,
  onclicksave
});

export const baseapiurlWebcomponentProp = baseapiurl;