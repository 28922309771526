import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import type { ColorResponseItem, PatternResponseItem, CuttingOptionResponseItem } from './responses';

export enum InputSizeButton {
  InputIncrease,
  InputDecrease,
}

export enum ControlArea {
  RotateLeft,
  RotateRight,
  ZoomIn,
  ZoomOut,
}

export enum ModelStyle {
  Shirt = 'model_shirt',
  Short = 'model_short',
}

export enum JerseySection {
  Front = 'front_main',
  Back = 'back_main',
  Left = 'left_main',
  Right = 'right_main',
  Collar = 'collar_main',
  CuffLeft = 'cuff_left',
  CuffRight = 'cuff_right',
  ShortElastic = 'short_elastic_main',
  ShortStripe = 'short_stripe_main',
}

export enum FunctionTab {
  ResetStart,
  Cut,
  Design,
  Color,
  Pattern,
  Number,
  Initial,
  Name,
  Text,
  Teamname,
  Logo,
  Image,
  Download
};

export enum NameTabType {
  Name = 'name',
  Initial = 'initial',
};

export enum CutCollarShape {
  RoundNeck = 'RN',
  VNeck = 'VN',
  Polo = 'PO'
}

export enum CutSleeveForm {
  SewnOnSleeve = 'SO',
  Raglan = 'RL',
}

export enum CutSleeveCutting {
  Short = 'SH',
  Long = 'LO',
  Tanktop = 'TT'
}

export enum CutFit {
  Standard = 'ST',
  Tailert = 'TL',
}

export enum CutMaterial {
  Int = 'INT',
  Be = 'BE',
}

export enum CutCollar {
  MainFabric = 'MF',
  Rib = 'RIB',
}

export enum CutCuff {
  CutAndSew = 'CS',
  MainFabric = 'MF',
  Rib = 'RIB',
}

export enum JakoLogoPosition {
  Left = 'left',
  Right = 'right',
  Center = 'center'
}

export enum BasicAddPosition {
  BackTop,
  BackBottom,
  FrontTopLeft,
  FrontTopCenter = 'Brust_zentriert',
  LeftArm = 'Armel_links',
  RightArm = 'Armel_rechts',
  FrontTopRight = 'Brust_links',
};

export enum LogoAddPosition {
  LeftArm = 'Armel_links',
  RightArm = 'Armel_rechts',
  FrontTopLeft = 'Brust_links',
  FrontMiddleFull = 'Brust_mittig',
  FrontUnderLogoFull = 'Brust_oben',
  FrontBottomFull = 'Brust_unten',
  FrontTopCenter = 'Brust_zentriert',
  Neck = 'Nacken',
  BackMiddleFull = 'Rucken_mittig',
  BackTopFull = 'Rucken_oben',
  BackBottomFull = 'Rucken_unten',
  ShortBottomLeft = 'Short_BottomLeft',
  ShortBottomRight = 'Short_BottomRight',
  ShortUpLeft = 'Short_UpLeft',
  ShortUpRight = 'Short_UpRight',
};

export enum NameInitialAddPosition {
  LeftArm = 'Armel_links',
  RightArm = 'Armel_rechts',
  FrontTopLeft = 'Brust_links',
  FrontTopCenter = 'Brust_zentriert',
  FrontMiddleFull = 'Brust_mittig',
  BackTopFull = 'Rucken_oben',
  BackSuperBottomFull = 'Rucken_super_unten',
  ShortBottomLeft = 'Short_BottomLeft',
  ShortBottomRight = 'Short_BottomRight',
  ShortUpLeft = 'Short_UpLeft',
  ShortUpRight = 'Short_UpRight',
};

export enum NumberAddPosition {
  LeftArm = 'Armel_links',
  RightArm = 'Armel_rechts',
  FrontTopLeft = 'Brust_links',
  FrontTopCenter = 'Brust_zentriert',
  BackMiddleFull = 'Rucken_mittig',
  ShortBottomLeft = 'Short_BottomLeft',
  ShortBottomRight = 'Short_BottomRight',
  ShortUpLeft = 'Short_UpLeft',
  ShortUpRight = 'Short_UpRight',
};

export enum TeamnameAddPosition {
  // FrontUnderLogoFull = 'Brust_oben',
  // FrontBottomFull = 'Brust_unten',
  BackTopFull = 'Rucken_oben',
  BackBottomFull = 'Rucken_unten',
  ShortBottomLeft = 'Short_BottomLeft',
  ShortBottomRight = 'Short_BottomRight',
  ShortUpLeft = 'Short_UpLeft',
  ShortUpRight = 'Short_UpRight',
};

export enum TextAddPosition {
  LeftArm = 'Armel_links',
  RightArm = 'Armel_rechts',
  FrontUnderLogoFull = 'Brust_oben',
  FrontBottomFull = 'Brust_unten',
  BackTopFull = 'Rucken_oben',
  BackBottomFull = 'Rucken_unten',
  Neck = 'Nacken',
  ShortBottomLeft = 'Short_BottomLeft',
  ShortBottomRight = 'Short_BottomRight',
  ShortUpLeft = 'Short_UpLeft',
  ShortUpRight = 'Short_UpRight',
};

export type TabOption = {
  functionTab: FunctionTab,
  icon: IconDefinition,
  label: string,
  fieldCode?: string
}

export type DesignOption = {
  name?: string,
  file: string,
  previewFile: string,
};

export type PatternOption = {
  id: number,
  svg: string,
  thumbnail: string,
};

export type LogoUploadObject = {
  src: string,
  fileName: string,
  reference: string
  isUnprocessable?: boolean,
}

export type TextEditorObject = {
  text: string,
  name: string,
  x: number,
  y: number,
  fontSize: number,
  width: number,
  height: number,
  scaledWidth: number,
  scaledHeight: number,
  maxHeight: number,
  reference: string,
  layerWidth: number,
  layerHeight: number,
  fieldCode: string,
  functionTab: FunctionTab,
  fill: string,
  rotation: number,
  isLock?: boolean,
  fontFamily?: string,
  foundedPositionPart: string,
  position: NameInitialAddPosition | TeamnameAddPosition | TextAddPosition | NumberAddPosition,
  initialX: number,
  initialY: number,
  mouse: {
    x: number,
    y: number
  },
  isMobilePositionUpdate?: boolean | null,
  isMobileInitialUpdate?: boolean | null,
  indexLayer: number,
}

export type LogoEditorObject = {
  isLock?: boolean,
  name: string,
  src: string,
  x: number,
  y: number,
  scaledWidth: number,
  scaledHeight: number,
  maxHeight: number,
  width: number,
  height: number,
  rotation: number,
  fileName: string,
  base64: string,
  fieldCode: string,
  foundedPositionPart: string,
  position: LogoAddPosition,
  initialX: number,
  initialY: number,
  mouse: {
    x: number,
    y: number
  },
  fill?: string,
  isUnprocessable?: boolean,
  possibleImageColors: {
    colorHexCode: string,
    colorInfoText: string,
  }[],
  reference: string | null,
  isMobilePositionUpdate?: boolean | null,
  isMobileInitialUpdate?: boolean | null,
  indexLayer: number,
}

export type PatternEditorObject = {
  svg: string,
  fill?: string,
  bgFill?: string,
  rotate?: number,
  scale?: number,
  top?: number,
  left?: number,
  filename: string,
  tileable: boolean,
  change_size: boolean,
  move_horizontally: boolean,
  move_vertically: boolean,
  description: string
}

export type AddedTextItems = {
  [key: string]: TextEditorObject
}

export type AddedLogoItems = {
  [key: string]: LogoEditorObject
}

export type AddedImagePosition = {
  imageObj: LogoUploadObject,
  x?: number,
  y?: number,
  width?: number,
  addSectionPositionRef?: LogoAddPosition,
};

export type AddedTextPosition = {
  textString: string,
  addingState: FunctionTab,
  addSectionPositionRef?: BasicAddPosition | TextAddPosition | TeamnameAddPosition | NameInitialAddPosition | NumberAddPosition,
  fontSize?: number,
};

export type AddedPatternJerseySectionItems = {
  [key: string]: PatternEditorObject
}

export type DownloadSvgFontToBase64 = {
  font: string,
  base64: string,
}

export interface AddTextInterface {
  adding: FunctionTab,
  object: any,
  reference: string,
  textSize: {
    maxHeight: number,
    // scaledHeight: number,
    // scaledWidth: number,
  },
  position?: BasicAddPosition | TextAddPosition | TeamnameAddPosition | NameInitialAddPosition | NumberAddPosition
}

export interface AddImageInterface {
  adding: FunctionTab,
  object: any,
  imageObj: LogoUploadObject,
  position?: LogoAddPosition,
  logoSize: {
    maxHeight: number,
    scaledHeight: number,
    scaledWidth: number,
  }
}

export interface NameTabInterface {
  functionTab: FunctionTab,
  modelStyle: ModelStyle,
  selectedAddedTextKey: string | null,
  addedTextItems: AddedTextItems
  showAddTextInputRef: React.RefObject<HTMLInputElement>,
  showEditTextInputRef: React.RefObject<HTMLInputElement>,
  colorList: ColorResponseItem[],
  onSubmitAddTextInput: (
    functionTab: FunctionTab,
  ) => void,
  onSubmitEditTextInput: (
    name: string,
    e: any,
    functionTab: FunctionTab,
    isNotEditReplaceAll?: boolean,
  ) => void,
  onClickTextLock: (itemKey: string) => void,
  onClickDeleteText: (itemKey: string) => void,
  onSelectText: (itemKey: string | null) => void,
  onSelectAddNamePosition: (part: JerseySection, position: NameInitialAddPosition, onlyCamera?: boolean) => void,
  onChangeTextColor: (color: string) => void,
  onChangeFontFamily: (name: string, font: string) => void,
  onChangeFontSizeWidth: (name: string, width: number) => void,
  onChangeFontSizeHeight: (name: string, width: number) => void,
  onChangePosition: (name: string, position: string, part?: JerseySection, isInit?: boolean) => void,
  onSendTextToFront: (name: string,) => void,
  onSendTextToBack: (name: string,) => void,
  onBack: () => void,
  onSetTabName: (tab: FunctionTab) => void,
  setSelectTabBack: () => void,
  setSelectTabNext: () => void,
  isMobile: boolean,
  onCloseMobile: () => void,
  cmFactor: number,
  onScrollToSelectedItem: (id: string) => void,
}

export interface NumberTabInterface {
  functionTab: FunctionTab,
  modelStyle: ModelStyle,
  selectedAddedTextKey: string | null,
  addedTextItems: AddedTextItems
  showAddTextInputRef: React.RefObject<HTMLInputElement>,
  showEditTextInputRef: React.RefObject<HTMLInputElement>,
  colorList: ColorResponseItem[],
  onSubmitAddTextInput: (
    functionTab: FunctionTab,
  ) => void,
  onSubmitEditTextInput: (
    name: string,
    e: any,
    functionTab: FunctionTab,
    isNotEditReplaceAll?: boolean,
  ) => void,
  onSelectAddNumberPosition: (part: JerseySection, position: NumberAddPosition, onlyCamera?: boolean) => void,
  onClickTextLock: (itemKey: string) => void,
  onClickDeleteText: (itemKey: string) => void,
  onSelectText: (itemKey: string | null) => void,
  onChangeTextColor: (color: string) => void,
  onChangeFontFamily: (name: string, font: string) => void,
  onChangeFontSizeWidth: (name: string, width: number) => void,
  onChangeFontSizeHeight: (name: string, height: number) => void,
  onChangePosition: (name: string, position: string, part?: JerseySection, isInit?: boolean) => void,
  onSendTextToFront: (name: string,) => void,
  onSendTextToBack: (name: string,) => void,
  onBack: () => void,
  setSelectTabBack: () => void,
  setSelectTabNext: () => void,
  isMobile: boolean,
  onCloseMobile: () => void,
  cmFactor: number,
  onScrollToSelectedItem: (id: string) => void,
}

export interface TeamnameTabInterface {
  modelStyle: ModelStyle,
  functionTab: FunctionTab,
  selectedAddedTextKey: string | null,
  addedTextItems: AddedTextItems
  showAddTextInputRef: React.RefObject<HTMLInputElement>,
  showEditTextInputRef: React.RefObject<HTMLInputElement>,
  colorList: ColorResponseItem[],
  onSubmitAddTextInput: (
    functionTab: FunctionTab,
  ) => void,
  onSubmitEditTextInput: (
    name: string,
    e: any,
    functionTab: FunctionTab,
    isNotEditReplaceAll?: boolean,
  ) => void,
  onSelectAddTeamnamePosition: (part: JerseySection, position: TeamnameAddPosition, onlyCamera?: boolean) => void,
  onClickTextLock: (itemKey: string) => void,
  onClickDeleteText: (itemKey: string) => void,
  onSelectText: (itemKey: string | null) => void,
  onChangeTextColor: (color: string) => void,
  onChangeFontFamily: (name: string, font: string) => void,
  onChangeFontSizeWidth: (name: string, width: number) => void,
  onChangeFontSizeHeight: (name: string, height: number) => void,
  onChangePosition: (name: string, position: string, part?: JerseySection, isInit?: boolean) => void,
  onSendTextToFront: (name: string,) => void,
  onSendTextToBack: (name: string,) => void,
  onBack: () => void,
  setSelectTabBack: () => void,
  setSelectTabNext: () => void,
  isMobile: boolean,
  onCloseMobile: () => void,
  cmFactor: number,
  onScrollToSelectedItem: (id: string) => void,
}


export interface TextTabInterface {
  functionTab: FunctionTab,
  modelStyle: ModelStyle,
  selectedAddedTextKey: string | null,
  addedTextItems: AddedTextItems
  showAddTextInputRef: React.RefObject<HTMLInputElement>,
  showEditTextInputRef: React.RefObject<HTMLInputElement>,
  colorList: ColorResponseItem[],
  onSubmitAddTextInput: (
    functionTab: FunctionTab,
  ) => void,
  onSubmitEditTextInput: (
    name: string,
    e: any,
    functionTab: FunctionTab,
    isNotEditReplaceAll?: boolean,
  ) => void,
  onSelectAddTextPosition: (part: JerseySection, position: TextAddPosition, onlyCamera?: boolean) => void,
  onClickTextLock: (itemKey: string) => void,
  onClickDeleteText: (itemKey: string) => void,
  onSelectText: (itemKey: string | null) => void,
  onChangeTextColor: (color: string) => void,
  onChangeFontFamily: (name: string, font: string) => void,
  onChangeFontSizeWidth: (name: string, width: number) => void,
  onChangeFontSizeHeight: (name: string, height: number) => void,
  onChangePosition: (name: string, position: string, part?: JerseySection, isInit?: boolean) => void,
  onSendTextToFront: (name: string,) => void,
  onSendTextToBack: (name: string,) => void,
  onBack: () => void,
  setSelectTabBack: () => void,
  setSelectTabNext: () => void,
  isMobile: boolean,
  onCloseMobile: () => void,
  cmFactor: number,
  onScrollToSelectedItem: (id: string) => void,
}

export interface LogoTabInterface {
  modelStyle: ModelStyle,
  selectedAddedLogoKey: string | null,
  addedLogoItems: AddedLogoItems,
  jakoLogoPosition: JakoLogoPosition,
  colorList: ColorResponseItem[],
  currentCuttingFormCode: string,
  onAddLogoInput: (data: {
    fileName: string,
    src: string,
    isUnprocessable: boolean,
    reference: string
  }) => void,
  onClickLogoLock: (itemKey: string) => void,
  onClickDeleteLogo: (itemKey: string) => void,
  onChangeLogoColor: (color: string) => void,
  onChangeWidthSize: (name: string, width: number) => void,
  onChangeHeightSize: (name: string, height: number) => void,
  onSendLogoToFront: (name: string) => void,
  onSendLogoToBack: (name: string) => void,
  onSelectLogo: (itemKey: string | null) => void,
  onSelectAddLogoPosition: (part: JerseySection, position: LogoAddPosition, onlyCamera?: boolean) => void,
  onSelectJakoLogoPosition: (part: JakoLogoPosition) => void,
  onChangeLogoPosition: (name: string, position: string, isInit?: boolean) => void,
  onSetLoading: (status: boolean) => void,
  setSelectTabBack: () => void,
  setSelectTabNext: () => void,
  isMobile: boolean,
  onCloseMobile: () => void,
  cmFactor: number,
  onScrollToSelectedItem: (id: string) => void,
}

export interface PatternTabInterface {
  modelStyle: ModelStyle,
  jerseySection: string,
  possibleDesignAreaId: string[],
  selectedJerseySectionPattern: AddedPatternJerseySectionItems,
  selectedJerseySectionColor: any,
  patternList: PatternResponseItem[],
  colorList: ColorResponseItem[],
  onSelectPatternSvg: (section: string, item: PatternResponseItem | null) => void,
  onSelectPatternFill: (section: string, fill: string) => void,
  onSelectBgColor: (section: any, color: string) => void,
  onSetJerseySection: (section: string) => void,
  onPatternScale: (section: string, e: number) => void,
  onPatternMoveOffset: (section: string, offset: {
    top: number,
    left: number
  }) => void,
  onPatternRotate: (section: string, e: number) => void,
  setSelectTabBack: () => void,
  setSelectTabNext: () => void,
  isMobile: boolean,
  onCloseMobile: () => void
}

export interface CuttingTabInterface {
  isEdited: boolean,
  cuttingOptions: CuttingOptionData[],
  cuttingFormLinksResponse: CuttingOptionResponseItem[],
  cuttingFormCode: string,
  onSelectMaterial: (material: CutMaterial) => void,
  onSelectCut: (cuttingFormCode: string, material: CutMaterial) => void,
  setSelectTabBack: () => void,
  setSelectTabNext: () => void,
  isMobile: boolean,
  onCloseMobile: () => void
}

export interface ColorTabInterface {
  modelStyle: ModelStyle,
  possibleDesignAreaId: string[],
  colorList: ColorResponseItem[],
  selectedJerseySectionColor: any,
  onSelectColor: (section: any, color: string) => void,
  setSelectTabBack: () => void,
  setSelectTabNext: () => void,
  isMobile: boolean,
  onCloseMobile: () => void
}

export type CuttingForm = {
  collarShape: CutCollarShape,
  sleeveForm: CutSleeveForm,
  sleeveCutting: CutSleeveCutting,
  fit: CutFit,
  material: CutMaterial,
  collar: CutCollar,
  cuff: CutCuff
}

export type CuttingData = {
  positionX: number,
  positionY: number,
  positionZ: number,
  cuttingForm: CuttingForm,
}

export type SaveConfigState = {
  designHeaderId: string,
  visitorId?: string,
  configurationName: string,
  selectedModel: {
    cuttingFormCode: string,
    templateCode: string,
    glbFile: string,
    glbSeparateFile: string,
    normalMapFile: string,
    dotsLeftFile: string,
    dotsRightFile: string,
    logoLeftFile: string,
    logoRightFile: string,
    logoCenterFile: string,
  },
  selectedDesign: DesignOption | null,
  colorLogoHexRef: string,
  colorDotsLeftHexRef: string,
  colorDotsRightHexRef: string,
  colorInvertedBgRef: string,
  selectedJerseySectionPattern: AddedPatternJerseySectionItems,
  selectedJerseySectionColor: any,
  addedFabricLogoObjects: AddedLogoItems,
  addedFabricTextObjects: AddedTextItems,
  jakoLogoPosition: JakoLogoPosition,
  isFromCustomDesign?: boolean,
  hasBackDesign: boolean,
  activateDesignOnBack: boolean,
}

export type CuttingOptionData = {
  code: string,
  codeDescription: string,
  codeSorting: number,
  cuttingFormCode: string,
  sorting: string,
  value: string,
  valueDescription: string,
  valueIconUrl: string,
  mappedItems: {
    code: string,
    codeDescription: string,
    codeSorting: number,
    cuttingFormCode: string,
    sorting: string,
    value: string,
    valueDescription: string,
    valueIconUrl: string,
    groupId: string,
    items: {
      code: string,
      codeDescription: string,
      codeSorting: number,
      cuttingFormCode: string,
      sorting: string,
      value: string,
      valueIconUrl: string,
      valueDescription: string,
    }[]
  }[],
  allPossibleItems: {
    code: string,
    codeDescription: string,
    codeSorting: number,
    cuttingFormCode: string,
    sorting: string,
    value: string,
    valueDescription: string,
    groupId: string,
    items: {
      code: string,
      codeDescription: string,
      codeSorting: number,
      cuttingFormCode: string,
      sorting: string,
      value: string,
      valueDescription: string,
    }[]
  }[]
}

export enum IndividualisationViewCode {
  Front,
  Back,
  SideLeft,
  SideRight,
  Collar,
}

export type AreaSurface = {
  name: string,
  color: string,
  colorCode: string,
  width: number,
  height: number,
  widthCm: number,
  heightCm: number,
  top: number,
  left: number,
  elements: AreaElement[],
  pattern: AreaElementPattern | null
};

export type AreaElement = {
  order: number,
  type: string,
  subType: string,
  content: string,
  font: string | null,
  width: number,
  height: number,
  widthCm: number,
  heightCm: number,
  top: number,
  left: number,
  cy: number,
  cx: number,
  color: string,
  colorCode: string,
  reference: string,
  rotation: number,
};

export type AreaElementPattern = {
  content: string,
  scale: number,
  top: number,
  left: number,
  tileable: boolean,
  color: string,
  colorCode: string,
  offsetX: number,
  offsetY: number,
  width: number,
  height: number,
};

export type DesignElement = {
  name: string,
  file: string,
  surfaces: DesignSurfaceDefinition[],
};

export type DesignSurfaceDefinition = {
  name: string,
  color: string,
  colorCode: string,
  position: string,
  pattern: AreaElementPattern | null,
}

export type ConfigElementsOrm = {
  designHeaderId: number,
  templateCode: string,
  cuttingFormCode: string,
  jakoLogo: {
    position: string,
    file: string,
    fillColor: string,
    fillColorCode: string,
    strokeWidth: number,
    strokeColor: string,
    strokeColorCode: string,
  },
  jakoDots: {
    left: {
      file: string,
      fillColor: string,
      fillColorCode: string,
    },
    right: {
      file: string,
      fillColor: string,
      fillColorCode: string,
    }
  } | null,
  design: DesignElement | null,
  surfaces: AreaSurface[]
};
