import { JerseySection, ModelStyle } from './types';
import type { ColorTabInterface } from './types';
import { useEffect, useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ColorResponseItem } from './responses';

import {
  faAngleRight,
  faAngleLeft
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import IconCross from './icons/IconCross';

enum SelectColorTabMobile {
  Frontside,
  Backside,
  Sleeve,
  Collar,
  Short,
  ShortStripe
}

const perRowItem = 6;

export default function JakoColorTab({
  modelStyle,
  colorList,
  possibleDesignAreaId,
  selectedJerseySectionColor,
  onSelectColor,
  setSelectTabBack,
  setSelectTabNext,
  isMobile,
  onCloseMobile,
}: ColorTabInterface) {
  const { t } = useTranslation('translations');
  const [showMoreColorSection, setShowMoreColorSection] = useState({
    [JerseySection.Front]: false,
    [JerseySection.Back]: false,
    [JerseySection.Left]: false,
    [JerseySection.Right]: false,
    [JerseySection.CuffLeft]: false,
    [JerseySection.CuffRight]: false,
    [JerseySection.ShortElastic]: false,
    [JerseySection.ShortStripe]: false,
  } as any);

  const scrollBarRef = useRef(null as any);
  const [hasMoreScrollContainer, setHasMoreScrollContainer] = useState(false);
  const [selectTabMobile, setSelectTabMobile] = useState<SelectColorTabMobile>(SelectColorTabMobile.Frontside);

  const hasShowingMoreColorSection = useMemo(() => Object.values(showMoreColorSection).some((val) => val) , [showMoreColorSection]);
  const onlyMainSectionKey = useMemo(
    () => Object.keys(selectedJerseySectionColor).slice()
      .filter((itemKey) => !itemKey.includes('neck') && !itemKey.includes('bg-') && !itemKey.includes('copy'))
  , [selectedJerseySectionColor]);
  const colorRowCount = useMemo(() => Math.ceil(colorList.length / perRowItem), [colorList]);

  const uniqueColorList = useMemo(() => {
    // arr.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
    return colorList.filter((v,i,a)=>a.findIndex(v2=>(v2.colorHexCode===v.colorHexCode))===i);
  }, [colorList]);

  const sectionColorOptions: {
    [key: string]: ColorResponseItem[][]
  } = useMemo(
    () => {
      let results = {};
      onlyMainSectionKey
        .forEach((itemKey: string) => {
          let reArrangeColorListResults = [...colorList];
          const foundedIndex = colorList.findIndex((colorItem) => '#' + colorItem.colorHexCode === selectedJerseySectionColor[itemKey]);
          // array.splice(to, 0, array.splice(from, 1)[0]);
          reArrangeColorListResults.splice(0, 0, reArrangeColorListResults.splice(foundedIndex, 1)[0]);
          let colorItemsPerRow: any = [];
          for (let row = 1; row <= colorRowCount; row++) {
            const perRowItems = reArrangeColorListResults.slice((row - 1) * perRowItem, row * perRowItem);
            colorItemsPerRow.push(perRowItems);
          }
          // colorItemsPerRow;
          results = {
            ...results,
            [itemKey]: colorItemsPerRow
          };
        });
      return results;
    }
  , [colorRowCount, colorList, onlyMainSectionKey, selectedJerseySectionColor]);

  let onlyPossibleDesignAreas: any = {};
  const getOrderedDesignAreaKeys = useRef([] as any);

  if (possibleDesignAreaId.length) {
    const onlyNonCopyItems = possibleDesignAreaId.slice().filter((area) => !area.includes('collar_under') && !area.includes('placket') && !area.includes('neck') && !area.includes('bg-') && !area.includes('copy'));
    onlyNonCopyItems.forEach((item) => {
      const getWordDesignPart = item.split('_')[0]; // design-1, design-2, design-3
      const getWordDesignJerseySectionPart = item.split('_')[1] ? item.split('_')[1] : ''; // front, back, left, right
      const getDesignWord = getWordDesignPart.split('-')[0] + (getWordDesignPart.split('-')[1] ? ' ' + getWordDesignPart.split('-')[1] : '');
      const getAreaName = getDesignWord + ' ' + getWordDesignJerseySectionPart;
      onlyPossibleDesignAreas = {
        ...onlyPossibleDesignAreas,
        [item]: getAreaName
      };
    });
    getOrderedDesignAreaKeys.current = Object.keys(onlyPossibleDesignAreas).sort((aKey: string, bKey: string) => {
      const getWordDesignPartA = aKey.split('_')[0]; // design-1, design-2, design-3
      const getWordDesignPartB = bKey.split('_')[0]; // design-1, design-2, design-3
      const getNumberA = parseInt(getWordDesignPartA.split('-')[1]);
      const getNumberB = parseInt(getWordDesignPartB.split('-')[1]);
      const getJerseySectionA = aKey.split('_')[1];
      const getJerseySectionB = bKey.split('_')[1];
      if (getJerseySectionA === 'front' && getJerseySectionB === 'front') {
        return getNumberA - getNumberB;
      } else if (getJerseySectionA === 'front') {
        return -1;
      } else if (getJerseySectionB === 'front') {
        return 1;
      } else if (getJerseySectionA === 'back' && getJerseySectionB === 'back') {
        return getNumberA - getNumberB;
      } else if (getJerseySectionA === 'back') {
        return -1;
      } else if (getJerseySectionB === 'back') {
        return 1;
      } else if (getJerseySectionA === 'left' && getJerseySectionB === 'left') {
        return getNumberA - getNumberB;
      } else if (getJerseySectionA === 'left') {
        return -1;
      } else if (getJerseySectionB === 'left') {
        return 1;
      } else if (getJerseySectionA === 'right' && getJerseySectionB === 'right') {
        return getNumberA - getNumberB;
      } else if (getJerseySectionA === 'right') {
        return -1;
      } else if (getJerseySectionB === 'right') {
        return 1;
      } else {
        return  getNumberA - getNumberB;
      }
    });
  }

  useEffect(() => {
    if (!scrollBarRef.current) {
      return;
    }

    onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
    scrollBarRef.current.getScrollElement()
      .addEventListener("scroll", function(e: any) { onScrollContainerUpdate(e.target); });
  }, []);

  useEffect(() => {
    if (modelStyle === ModelStyle.Short) {
      setSelectTabMobile(SelectColorTabMobile.Short);
    } else {
      setSelectTabMobile(SelectColorTabMobile.Frontside);
    }
  }, [modelStyle]);
  useEffect(() => {
    if (getOrderedDesignAreaKeys.current.length) {
      let results = {};
      getOrderedDesignAreaKeys.current.forEach((itemKey: string) => {
        results = {
          ...results,
          [itemKey]: false
        };
      });
      setShowMoreColorSection({
        ...showMoreColorSection,
        ...results,
      });
    }
    // eslint-disable-next-line
  }, [colorList]);

  const frontDesignAreaKeys = useMemo(() => getOrderedDesignAreaKeys.current.slice().filter((key: string) => key.includes('_front')), [getOrderedDesignAreaKeys]);
  const backDesignAreaKeys = useMemo(() => getOrderedDesignAreaKeys.current.slice().filter((key: string) => key.includes('_back')), [getOrderedDesignAreaKeys]);

  function handleOnSelectOption(section: string) {
    let results = {};
    Object.keys(getOrderedDesignAreaKeys.current).forEach((itemKey) => {
      results = {
        ...results,
        [itemKey]: false
      };
    });
    results = {
      [JerseySection.Front]: false,
      [JerseySection.Back]: false,
      [JerseySection.Left]: false,
      [JerseySection.Right]: false,
      [JerseySection.CuffLeft]: false,
      [JerseySection.CuffRight]: false,
      [JerseySection.ShortElastic]: false,
      [JerseySection.ShortStripe]: false,
      ...results,
      [section]: !showMoreColorSection[section],
    };

    setShowMoreColorSection(results);
  }

  function getSelectedColorOptionItemFromHex(hex: string) {
    const foundedColor = colorList.find((item) => ('#'+item.colorHexCode) === hex);
    if (foundedColor) {
      return foundedColor;
    }
    return colorList[0];
  }

  function onScrollContainerUpdate(el: any) {
    if (el.clientHeight + el.scrollTop >= el.scrollHeight) {
      setHasMoreScrollContainer(false);
    } else {
      setHasMoreScrollContainer(true);
    }
  }

  function handleOnClickCloseMobile() {
    if (scrollBarRef.current) {
      onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
    }
    onCloseMobile();
  }

  function hideAllColorSection() {
    let results = {};
    Object.keys(getOrderedDesignAreaKeys.current).forEach((itemKey) => {
      results = {
        ...results,
        [itemKey]: false
      };
    });
    results = {
      [JerseySection.Front]: false,
      [JerseySection.Back]: false,
      [JerseySection.Left]: false,
      [JerseySection.Right]: false,
      [JerseySection.CuffLeft]: false,
      [JerseySection.CuffRight]: false,
      [JerseySection.ShortElastic]: false,
      [JerseySection.ShortStripe]: false,
      ...results,
    };
    setShowMoreColorSection(results);
  }

  function onSelectTabMobile(selectTab: SelectColorTabMobile) {
    hideAllColorSection();
    setSelectTabMobile(selectTab);
  }

  if (Object.keys(sectionColorOptions).length === 0) {
    return (
      <>
        <div className="h1 text-center">{ t('configurator_3d_choose_colors') }</div>
        <div className="text-center marginBottom--small">{ t('configurator_3d_choose_colors_text') }</div>
      </>
    );
  }

  return modelStyle === ModelStyle.Shirt
    ? isMobile
      ? <div
        className="relative w-full px-6 pt-10 pb-6"
        style={{
          height: hasShowingMoreColorSection ? '325px' : '150px'
        }}
      >
        <button
          className="absolute top-[21px] right-[7px] z-10"
          onClick={handleOnClickCloseMobile}
        >
          <IconCross className="h-[26px]" />
        </button>
        <div className="relative w-full pb-3 flex items-center gap-5 items-center mb-4 before:absolute before:w-full before:h-[2px] before:bottom-[1px] before:left-0 before:bg-[#F0F0F0]">
          <button
            className={
              "relative font-semibold text-[14px] before:absolute before:w-full before:h-[2px] before:bg-[#141414] before:left-0 before:bottom-[-6px] "
              + (selectTabMobile === SelectColorTabMobile.Frontside ? 'text-[#141414] before:opacity-100' : 'text-[#C3C3C3] before:opacity-0')
            }
            onClick={() => onSelectTabMobile(SelectColorTabMobile.Frontside)}
          >
            { t('configurator_3d_front_side') }
          </button>
          <button
            className={
              "relative font-semibold text-[14px] before:absolute before:w-full before:h-[2px] before:bg-[#141414] before:left-0 before:bottom-[-6px]  "
              + (selectTabMobile === SelectColorTabMobile.Backside ? 'text-[#141414] before:opacity-100' : 'text-[#C3C3C3] before:opacity-0')
            }
            onClick={() => onSelectTabMobile(SelectColorTabMobile.Backside)}
          >
            { t('configurator_3d_backside') }
          </button>
          <button
            className={
              "relative font-semibold text-[14px] before:absolute before:w-full before:h-[2px] before:bg-[#141414] before:left-0 before:bottom-[-6px] "
              + (selectTabMobile === SelectColorTabMobile.Collar ? 'text-[#141414] before:opacity-100' : 'text-[#C3C3C3] before:opacity-0')
            }
            onClick={() => onSelectTabMobile(SelectColorTabMobile.Collar)}
          >
            { t('configurator_3d_collar') }
          </button>
          <button
            className={
              "relative font-semibold text-[14px] before:absolute before:w-full before:h-[2px] before:bg-[#141414] before:left-0 before:bottom-[-6px] "
              + (selectTabMobile === SelectColorTabMobile.Sleeve ? 'text-[#141414] before:opacity-100' : 'text-[#C3C3C3] before:opacity-0')
            }
            onClick={() => onSelectTabMobile(SelectColorTabMobile.Sleeve)}
          >
            { t('configurator_3d_sleeve') }
          </button>
        </div>
        <SimpleBar
          ref={scrollBarRef}
          style={{
            width: '100%',
            height: hasShowingMoreColorSection ? '262px' : '',
          }}
          autoHide={false}
          className="color-picker-simplebar"
          onScroll={onScrollContainerUpdate}
        >
          {
            selectTabMobile === SelectColorTabMobile.Frontside
              ? <div className="flex flex-col min-h-full mb-[10px]">
                <div className="flex justify-between">
                  <p className="text-[14px] pt-3">{ t('configurator_3d_choose_colors') }</p>
                  <div className="colorWrapper colorWrapper--flex-responsive">
                    <div
                      className={
                        "relative itemColor !pb-8 !h-auto "
                        + (showMoreColorSection[JerseySection.Front] ? 'active' : '')
                      }
                      onClick={() => handleOnSelectOption(JerseySection.Front)}
                    >
                      <div
                        className={
                          "itemColor--bgColor"
                          + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Front]).code === '01_SU_WEISS' ? ' border' : '')
                        }
                        style={{
                          backgroundColor: selectedJerseySectionColor[JerseySection.Front]
                        }}
                      />
                      <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_front_side') }</p>
                    </div>
                    {
                      frontDesignAreaKeys.length
                        ? frontDesignAreaKeys.map((designKey: any, index: number) =>
                          <div
                            key={'frontDesign' + index + designKey}
                            className={
                              "relative itemColor !pb-8 !h-auto "
                              + (showMoreColorSection[designKey] ? 'active' : '')
                            }
                            onClick={() => handleOnSelectOption(designKey)}
                          >
                            <div
                              className={
                                "itemColor--bgColor"
                                + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).code === '01_SU_WEISS' ? ' border' : '')
                              }
                              style={{
                                backgroundColor: selectedJerseySectionColor[designKey]
                              }}
                            />
                            <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_shirt_design_no') + ' ' + (index + 1) }</p>
                          </div>
                        )
                        : <></>
                    }
                  </div>
                </div>
                <div
                  className={
                    "colorWrapper colorWrapper--responsive mt-4 pt-4 border-t-2 border-[#F0F0F0]"
                    + (showMoreColorSection[JerseySection.Front] ? ' grid' : ' hidden')
                  }
                >
                  {
                    uniqueColorList.map((item, index) =>
                      <div
                        key={'frontColor' + index + '#' + item.colorHexCode + item.description}
                        className={
                          "itemColor "
                          + ('#' + item.colorHexCode === selectedJerseySectionColor[JerseySection.Front] ? 'active' : '')
                        }
                        onClick={() => onSelectColor(JerseySection.Front, '#' + item.colorHexCode)}
                      >
                        <div
                          className={
                            "itemColor--bgColor"
                            + (item.code === '01_SU_WEISS' ? ' border' : '')
                          }
                          style={{
                            backgroundColor: '#' + item.colorHexCode
                          }}
                        />
                      </div>
                    )
                  }
                </div>
                {
                  frontDesignAreaKeys.length
                    ? frontDesignAreaKeys.map((designKey: any, orderIndex: number) =>
                        <div
                          key={'designKeyslideContent__show' + designKey + orderIndex}
                          className={
                            'colorWrapper colorWrapper--responsive mt-4 pt-4 border-t-2 border-[#F0F0F0]'
                            + (showMoreColorSection[designKey] ? ' grid' : ' hidden')
                          }
                        >
                          {
                            uniqueColorList.map((item, index) =>
                              <div
                                key={designKey + index + '#' + item.colorHexCode + item.description}
                                className={
                                  "itemColor "
                                  + ('#' + item.colorHexCode === selectedJerseySectionColor[designKey] ? 'active' : '')
                                }
                                onClick={() => onSelectColor(designKey, '#' + item.colorHexCode)}
                              >
                                <div
                                  className={
                                    "itemColor--bgColor"
                                    + (item.code === '01_SU_WEISS' ? ' border' : '')
                                  }
                                  style={{
                                    backgroundColor: '#' + item.colorHexCode
                                  }}
                                />
                              </div>
                            )
                          }
                        </div>
                      )
                    : <></>
                }
              </div>
              : <></>
          }
          {
            selectTabMobile === SelectColorTabMobile.Backside
              ? <div className="flex flex-col min-h-full mb-[10px]">
                <div className="flex justify-between">
                  <p className="text-[14px] pt-3">{ t('configurator_3d_choose_colors') }</p>
                  <div className="colorWrapper colorWrapper--flex-responsive">
                    <div
                      className={
                        "relative itemColor !pb-8 !h-auto "
                        + (showMoreColorSection[JerseySection.Back] ? 'active' : '')
                      }
                      onClick={() => handleOnSelectOption(JerseySection.Back)}
                    >
                      <div
                        className={
                          "itemColor--bgColor"
                          + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Back]).code === '01_SU_WEISS' ? ' border' : '')
                        }
                        style={{
                          backgroundColor: selectedJerseySectionColor[JerseySection.Back]
                        }}
                      />
                      <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_backside') }</p>
                    </div>

                    {
                      backDesignAreaKeys.length
                        ? backDesignAreaKeys.map((designKey: any, index: number) =>
                          <div
                            key={'backDesign' + index + designKey}
                            className={
                              "relative itemColor !pb-8 !h-auto "
                              + (showMoreColorSection[designKey] ? 'active' : '')
                            }
                            onClick={() => handleOnSelectOption(designKey)}
                          >
                            <div
                              className={
                                "itemColor--bgColor"
                                + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).code === '01_SU_WEISS' ? ' border' : '')
                              }
                              style={{
                                backgroundColor: selectedJerseySectionColor[designKey]
                              }}
                            />
                            <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_shirt_design_no') + ' ' + (index + 1) }</p>
                          </div>
                        )
                        : <></>
                    }
                  </div>
                </div>
                <div
                  className={
                    "colorWrapper colorWrapper--responsive mt-4 pt-4 border-t-2 border-[#F0F0F0]"
                    + (showMoreColorSection[JerseySection.Back] ? ' grid' : ' hidden')
                  }
                >
                  {
                    uniqueColorList.map((item, index) =>
                      <div
                        key={'frontColor' + index + '#' + item.colorHexCode + item.description}
                        className={
                          "itemColor "
                          + ('#' + item.colorHexCode === selectedJerseySectionColor[JerseySection.Back] ? 'active' : '')
                        }
                        onClick={() => onSelectColor(JerseySection.Back, '#' + item.colorHexCode)}
                      >
                        <div
                          className={
                            "itemColor--bgColor"
                            + (item.code === '01_SU_WEISS' ? ' border' : '')
                          }
                          style={{
                            backgroundColor: '#' + item.colorHexCode
                          }}
                        />
                      </div>
                    )
                  }
                </div>

                {
                  backDesignAreaKeys.length
                    ? backDesignAreaKeys.map((designKey: any, orderIndex: number) =>
                        <div
                          key={'designKeyslideContent__show' + designKey + orderIndex}
                          className={
                            'colorWrapper colorWrapper--responsive mt-4 pt-4 border-t-2 border-[#F0F0F0]'
                            + (showMoreColorSection[designKey] ? ' grid' : ' hidden')
                          }
                        >
                          {
                            uniqueColorList.map((item, index) =>
                              <div
                                key={designKey + index + '#' + item.colorHexCode + item.description}
                                className={
                                  "itemColor "
                                  + ('#' + item.colorHexCode === selectedJerseySectionColor[designKey] ? 'active' : '')
                                }
                                onClick={() => onSelectColor(designKey, '#' + item.colorHexCode)}
                              >
                                <div
                                  className={
                                    "itemColor--bgColor"
                                    + (item.code === '01_SU_WEISS' ? ' border' : '')
                                  }
                                  style={{
                                    backgroundColor: '#' + item.colorHexCode
                                  }}
                                />
                              </div>
                            )
                          }
                        </div>
                      )
                    : <></>
                }
              </div>
              : <></>
          }
          {
            selectTabMobile === SelectColorTabMobile.Collar
              ? <div className="flex flex-col min-h-full mb-[10px]">
                <div className="flex justify-between">
                  <p className="text-[14px] pt-3">{ t('configurator_3d_choose_colors') }</p>
                  <div className="colorWrapper colorWrapper--flex-responsive">
                    <div
                      className={
                        "relative itemColor !pb-8 !h-auto "
                        + (showMoreColorSection[JerseySection.Collar] ? 'active' : '')
                      }
                      onClick={() => handleOnSelectOption(JerseySection.Collar)}
                    >
                      <div
                        className={
                          "itemColor--bgColor"
                          + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Collar]).code === '01_SU_WEISS' ? ' border' : '')
                        }
                        style={{
                          backgroundColor: selectedJerseySectionColor[JerseySection.Collar]
                        }}
                      />
                      <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_collar') }</p>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    "colorWrapper colorWrapper--responsive mt-4 pt-4 border-t-2 border-[#F0F0F0]"
                    + (showMoreColorSection[JerseySection.Collar] ? ' grid' : ' hidden')
                  }
                >
                  {
                    uniqueColorList.map((item, index) =>
                      <div
                        key={'frontColor' + index + '#' + item.colorHexCode + item.description}
                        className={
                          "itemColor "
                          + ('#' + item.colorHexCode === selectedJerseySectionColor[JerseySection.Collar] ? 'active' : '')
                        }
                        onClick={() => onSelectColor(JerseySection.Collar, '#' + item.colorHexCode)}
                      >
                        <div
                          className={
                            "itemColor--bgColor"
                            + (item.code === '01_SU_WEISS' ? ' border' : '')
                          }
                          style={{
                            backgroundColor: '#' + item.colorHexCode
                          }}
                        />
                      </div>
                    )
                  }
                </div>
              </div>
              : <></>
          }
          {
            selectTabMobile === SelectColorTabMobile.Sleeve
              ? <div className="flex flex-col min-h-full mb-[10px]">
                <div className="flex justify-between">
                  <p className="text-[14px] pt-3">{ t('configurator_3d_choose_colors') }</p>
                  <div className="colorWrapper colorWrapper--flex-responsive">
                    <div
                      className={
                        "relative itemColor !pb-8 !h-auto "
                        + (showMoreColorSection[JerseySection.Right] ? 'active' : '')
                      }
                      onClick={() => handleOnSelectOption(JerseySection.Right)}
                    >
                      <div
                        className={
                          "itemColor--bgColor"
                          + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Right]).code === '01_SU_WEISS' ? ' border' : '')
                        }
                        style={{
                          backgroundColor: selectedJerseySectionColor[JerseySection.Right]
                        }}
                      />
                      <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_sleeve_right') }</p>
                    </div>
                    <div
                      className={
                        "relative itemColor !pb-8 !h-auto "
                        + (showMoreColorSection[JerseySection.Left] ? 'active' : '')
                      }
                      onClick={() => handleOnSelectOption(JerseySection.Left)}
                    >
                      <div
                        className={
                          "itemColor--bgColor"
                          + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Left]).code === '01_SU_WEISS' ? ' border' : '')
                        }
                        style={{
                          backgroundColor: selectedJerseySectionColor[JerseySection.Left]
                        }}
                      />
                      <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_sleeve_left') }</p>
                    </div>
                    {
                      sectionColorOptions && sectionColorOptions.cuff_left
                        ? <div
                          className={
                            "relative itemColor !pb-8 !h-auto "
                            + (showMoreColorSection[JerseySection.CuffLeft] ? 'active' : '')
                          }
                          onClick={() => handleOnSelectOption(JerseySection.CuffLeft)}
                        >
                          <div
                            className={
                              "itemColor--bgColor"
                              + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.CuffLeft]).code === '01_SU_WEISS' ? ' border' : '')
                            }
                            style={{
                              backgroundColor: selectedJerseySectionColor[JerseySection.CuffLeft]
                            }}
                          />
                          <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_cuff_left') }</p>
                        </div>
                        : <></>
                    }
                    {
                      sectionColorOptions && sectionColorOptions.cuff_right
                        ? <div
                          className={
                            "relative itemColor !pb-8 !h-auto "
                            + (showMoreColorSection[JerseySection.CuffRight] ? 'active' : '')
                          }
                          onClick={() => handleOnSelectOption(JerseySection.CuffRight)}
                        >
                          <div
                            className={
                              "itemColor--bgColor"
                              + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.CuffRight]).code === '01_SU_WEISS' ? ' border' : '')
                            }
                            style={{
                              backgroundColor: selectedJerseySectionColor[JerseySection.CuffRight]
                            }}
                          />
                          <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_cuff_right') }</p>
                        </div>
                        : <></>
                    }
                    {
                      sectionColorOptions && sectionColorOptions.elbow_left
                        ? <div
                          className={
                            "relative itemColor !pb-8 !h-auto "
                            + (showMoreColorSection['elbow_left'] ? 'active' : '')
                          }
                          onClick={() => handleOnSelectOption('elbow_left')}
                        >
                          <div
                            className={
                              "itemColor--bgColor"
                              + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elbow_left']).code === '01_SU_WEISS' ? ' border' : '')
                            }
                            style={{
                              backgroundColor: selectedJerseySectionColor['elbow_left']
                            }}
                          />
                          <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_cuff_right') }</p>
                        </div>
                        : <></>
                    }
                    {
                      sectionColorOptions && sectionColorOptions.elbow_right
                        ? <div
                          className={
                            "relative itemColor !pb-8 !h-auto "
                            + (showMoreColorSection['elbow_right'] ? 'active' : '')
                          }
                          onClick={() => handleOnSelectOption('elbow_right')}
                        >
                          <div
                            className={
                              "itemColor--bgColor"
                              + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elbow_right']).code === '01_SU_WEISS' ? ' border' : '')
                            }
                            style={{
                              backgroundColor: selectedJerseySectionColor['elbow_right']
                            }}
                          />
                          <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_cuff_right') }</p>
                        </div>
                        : <></>
                    }
                  </div>
                </div>
                <div
                  className={
                    "colorWrapper colorWrapper--responsive mt-4 pt-4 border-t-2 border-[#F0F0F0]"
                    + (showMoreColorSection[JerseySection.Right] ? ' grid' : ' hidden')
                  }
                >
                  {
                    uniqueColorList.map((item, index) =>
                      <div
                        key={'frontColor' + index + '#' + item.colorHexCode + item.description}
                        className={
                          "itemColor "
                          + ('#' + item.colorHexCode === selectedJerseySectionColor[JerseySection.Right] ? 'active' : '')
                        }
                        onClick={() => onSelectColor(JerseySection.Right, '#' + item.colorHexCode)}
                      >
                        <div
                          className={
                            "itemColor--bgColor"
                            + (item.code === '01_SU_WEISS' ? ' border' : '')
                          }
                          style={{
                            backgroundColor: '#' + item.colorHexCode
                          }}
                        />
                      </div>
                    )
                  }
                </div>
                <div
                  className={
                    "colorWrapper colorWrapper--responsive mt-4 pt-4 border-t-2 border-[#F0F0F0]"
                    + (showMoreColorSection[JerseySection.Left] ? ' grid' : ' hidden')
                  }
                >
                  {
                    uniqueColorList.map((item, index) =>
                      <div
                        key={'frontColor' + index + '#' + item.colorHexCode + item.description}
                        className={
                          "itemColor "
                          + ('#' + item.colorHexCode === selectedJerseySectionColor[JerseySection.Left] ? 'active' : '')
                        }
                        onClick={() => onSelectColor(JerseySection.Left, '#' + item.colorHexCode)}
                      >
                        <div
                          className={
                            "itemColor--bgColor"
                            + (item.code === '01_SU_WEISS' ? ' border' : '')
                          }
                          style={{
                            backgroundColor: '#' + item.colorHexCode
                          }}
                        />
                      </div>
                    )
                  }
                </div>
                {
                  sectionColorOptions && sectionColorOptions.cuff_left
                    ? <div
                      className={
                        "colorWrapper colorWrapper--responsive mt-4 pt-4 border-t-2 border-[#F0F0F0]"
                        + (showMoreColorSection[JerseySection.CuffLeft] ? ' grid' : ' hidden')
                      }
                    >
                      {
                        uniqueColorList.map((item, index) =>
                          <div
                            key={'frontColor' + index + '#' + item.colorHexCode + item.description}
                            className={
                              "itemColor "
                              + ('#' + item.colorHexCode === selectedJerseySectionColor[JerseySection.CuffLeft] ? 'active' : '')
                            }
                            onClick={() => onSelectColor(JerseySection.CuffLeft, '#' + item.colorHexCode)}
                          >
                            <div
                              className={
                                "itemColor--bgColor"
                                + (item.code === '01_SU_WEISS' ? ' border' : '')
                              }
                              style={{
                                backgroundColor: '#' + item.colorHexCode
                              }}
                            />
                          </div>
                        )
                      }
                    </div>
                    : <></>
                }
                {
                  sectionColorOptions && sectionColorOptions.cuff_right
                    ? <div
                      className={
                        "colorWrapper colorWrapper--responsive mt-4 pt-4 border-t-2 border-[#F0F0F0]"
                        + (showMoreColorSection[JerseySection.CuffRight] ? ' grid' : ' hidden')
                      }
                    >
                      {
                        uniqueColorList.map((item, index) =>
                          <div
                            key={'frontColor' + index + '#' + item.colorHexCode + item.description}
                            className={
                              "itemColor "
                              + ('#' + item.colorHexCode === selectedJerseySectionColor[JerseySection.CuffRight] ? 'active' : '')
                            }
                            onClick={() => onSelectColor(JerseySection.CuffRight, '#' + item.colorHexCode)}
                          >
                            <div
                              className={
                                "itemColor--bgColor"
                                + (item.code === '01_SU_WEISS' ? ' border' : '')
                              }
                              style={{
                                backgroundColor: '#' + item.colorHexCode
                              }}
                            />
                          </div>
                        )
                      }
                    </div>
                    : <></>
                }
                {
                  sectionColorOptions && sectionColorOptions.elbow_left
                    ? <div
                      className={
                        "colorWrapper colorWrapper--responsive mt-4 pt-4 border-t-2 border-[#F0F0F0]"
                        + (showMoreColorSection['elbow_left'] ? ' grid' : ' hidden')
                      }
                    >
                      {
                        uniqueColorList.map((item, index) =>
                          <div
                            key={'frontColor' + index + '#' + item.colorHexCode + item.description}
                            className={
                              "itemColor "
                              + ('#' + item.colorHexCode === selectedJerseySectionColor['elbow_left'] ? 'active' : '')
                            }
                            onClick={() => onSelectColor('elbow_left', '#' + item.colorHexCode)}
                          >
                            <div
                              className={
                                "itemColor--bgColor"
                                + (item.code === '01_SU_WEISS' ? ' border' : '')
                              }
                              style={{
                                backgroundColor: '#' + item.colorHexCode
                              }}
                            />
                          </div>
                        )
                      }
                    </div>
                    : <></>
                }
                {
                  sectionColorOptions && sectionColorOptions.elbow_right
                    ? <div
                      className={
                        "colorWrapper colorWrapper--responsive mt-4 pt-4 border-t-2 border-[#F0F0F0]"
                        + (showMoreColorSection['elbow_right'] ? ' grid' : ' hidden')
                      }
                    >
                      {
                        uniqueColorList.map((item, index) =>
                          <div
                            key={'frontColor' + index + '#' + item.colorHexCode + item.description}
                            className={
                              "itemColor "
                              + ('#' + item.colorHexCode === selectedJerseySectionColor['elbow_right'] ? 'active' : '')
                            }
                            onClick={() => onSelectColor('elbow_right', '#' + item.colorHexCode)}
                          >
                            <div
                              className={
                                "itemColor--bgColor"
                                + (item.code === '01_SU_WEISS' ? ' border' : '')
                              }
                              style={{
                                backgroundColor: '#' + item.colorHexCode
                              }}
                            />
                          </div>
                        )
                      }
                    </div>
                    : <></>
                }
              </div>
              : <></>
          }
        </SimpleBar>
        {
          hasMoreScrollContainer
            ? <div
              className={
                "absolute w-full h-[40px] bottom-[-1px] left-0 bg-gradient-to-b from-transparent to-white transition-all duration-200"
                + (hasMoreScrollContainer ? ' opacity-100' : ' opacity-0')
              }
            />
            : <></>
        }
      </div>
      : <>
        <div className="h1 text-center">{ t('configurator_3d_choose_colors') }</div>
        <div className="text-center marginBottom--small">{ t('configurator_3d_choose_colors_text') }</div>
        <div className="color-tab slideContent">
          <div className="py-4">
            <div className="slideContainer">
              <p className="slideContainer__headline marginBottom--xsmall">
                { t('configurator_3d_front_side') } / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Front]).description } {
                  frontDesignAreaKeys.length
                    ? frontDesignAreaKeys.map((designKey: string) => '/ ' + getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).description)
                    : ''
                }
              </p>
              <div className="colorWrapper colorWrapper--7cols">
                <div
                  className={
                    "relative itemColor !pb-8 !h-auto "
                    + (showMoreColorSection[JerseySection.Front] ? 'active' : '')
                  }
                  onClick={() => handleOnSelectOption(JerseySection.Front)}
                >
                  <div
                    className={
                      "itemColor--bgColor tooltip"
                      + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Front]).code === '01_SU_WEISS' ? ' border' : '')
                    }
                    style={{
                      backgroundColor: selectedJerseySectionColor[JerseySection.Front]
                    }}
                    data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Front]).description}
                  >
                    <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Front]).description }</label>
                  </div>
                  <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_front_side') }</p>
                </div>
                {
                  frontDesignAreaKeys.length
                    ? frontDesignAreaKeys.map((designKey: any, index: number) =>
                      <div
                        key={'frontDesign' + index + designKey}
                        className={
                          "relative itemColor !pb-8 !h-auto "
                          + (showMoreColorSection[designKey] ? 'active' : '')
                        }
                        onClick={() => handleOnSelectOption(designKey)}
                      >
                        <div
                          className={
                            "itemColor--bgColor tooltip"
                            + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).code === '01_SU_WEISS' ? ' border' : '')
                          }
                          style={{
                            backgroundColor: selectedJerseySectionColor[designKey]
                          }}
                          data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).description}
                        >
                          <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).description }</label>
                        </div>
                        <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_shirt_design_no') + ' ' + (index + 1) }</p>
                      </div>
                    )
                    : <></>
                }
              </div>

              <div
                className={
                  "colorWrapper colorWrapper--7cols mt-4"
                  + (showMoreColorSection[JerseySection.Front] ? ' grid' : ' hidden')
                }
              >
                {
                  uniqueColorList.map((item, index) =>
                    <div
                      key={'frontColor' + index + '#' + item.colorHexCode + item.description}
                      className={
                        "itemColor "
                        + ('#' + item.colorHexCode === selectedJerseySectionColor[JerseySection.Front] ? 'active' : '')
                      }
                      onClick={() => onSelectColor(JerseySection.Front, '#' + item.colorHexCode)}
                    >
                      <div
                        className={
                          "itemColor--bgColor tooltip"
                          + (item.code === '01_SU_WEISS' ? ' border' : '')
                        }
                        style={{
                          backgroundColor: '#' + item.colorHexCode
                        }}
                        data-tooltip={item.description}
                      >
                        <label className="tooltipBubble">{ item.description }</label>
                      </div>
                    </div>
                  )
                }
              </div>

              {
                frontDesignAreaKeys.length
                  ? frontDesignAreaKeys.map((designKey: any, orderIndex: number) =>
                      <div
                        key={'designKeyslideContent__show' + designKey + orderIndex}
                        className={'colorWrapper colorWrapper--7cols mt-4' + (showMoreColorSection[designKey] ? ' grid' : ' hidden')}
                      >
                        {
                          uniqueColorList.map((item, index) =>
                            <div
                              key={designKey + index + '#' + item.colorHexCode + item.description}
                              className={
                                "itemColor "
                                + ('#' + item.colorHexCode === selectedJerseySectionColor[designKey] ? 'active' : '')
                              }
                              onClick={() => onSelectColor(designKey, '#' + item.colorHexCode)}
                            >
                              <div
                                className={
                                  "itemColor--bgColor tooltip"
                                  + (item.code === '01_SU_WEISS' ? ' border' : '')
                                }
                                style={{
                                  backgroundColor: '#' + item.colorHexCode
                                }}
                                data-tooltip={item.description}
                              >
                                <label className="tooltipBubble">{ item.description }</label>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    )
                  : <></>
              }
            </div>

            <div className="slideContainer">
              <p className="slideContainer__headline marginBottom--xsmall">
                { t('configurator_3d_backside') } / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Back]).description }
                {
                  backDesignAreaKeys.length
                  ? backDesignAreaKeys.map((designKey: string) => '/ ' + getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).description)
                  : ''
                }
              </p>
              <div className="colorWrapper colorWrapper--7cols">
                <div
                  className={
                    "relative itemColor !pb-8 !h-auto "
                    + (showMoreColorSection[JerseySection.Back] ? 'active' : '')
                  }
                  onClick={() => handleOnSelectOption(JerseySection.Back)}
                >
                  <div
                    className={
                      "itemColor--bgColor tooltip"
                      + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Back]).code === '01_SU_WEISS' ? ' border' : '')
                    }
                    style={{
                      backgroundColor: selectedJerseySectionColor[JerseySection.Back]
                    }}
                    data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Back]).description}
                  >
                    <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Back]).description }</label>
                  </div>
                  <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_backside') }</p>
                </div>
                {
                  backDesignAreaKeys.length
                    ? backDesignAreaKeys.map((designKey: any, index: number) =>
                      <div
                        key={'backDesign' + index + designKey}
                        className={
                          "relative itemColor !pb-8 !h-auto "
                          + (showMoreColorSection[designKey] ? 'active' : '')
                        }
                        onClick={() => handleOnSelectOption(designKey)}
                      >
                        <div
                          className={
                            "itemColor--bgColor tooltip"
                            + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).code === '01_SU_WEISS' ? ' border' : '')
                          }
                          style={{
                            backgroundColor: selectedJerseySectionColor[designKey]
                          }}
                          data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).description}
                        >
                          <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).description }</label>
                        </div>
                        <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_shirt_design_no') + ' ' + (index + 1) }</p>
                      </div>
                    )
                    : <></>
                }
              </div>

              <div
                className={
                  "colorWrapper colorWrapper--7cols mt-4"
                  + (showMoreColorSection[JerseySection.Back] ? ' grid' : ' hidden')
                }
              >
                {
                  uniqueColorList.map((item, index) =>
                    <div
                      key={'backColor' + index + '#' + item.colorHexCode + item.description}
                      className={
                        "itemColor "
                        + ('#' + item.colorHexCode === selectedJerseySectionColor[JerseySection.Back] ? 'active' : '')
                      }
                      onClick={() => onSelectColor(JerseySection.Back, '#' + item.colorHexCode)}
                    >
                      <div
                        className={
                          "itemColor--bgColor tooltip"
                          + (item.code === '01_SU_WEISS' ? ' border' : '')
                        }
                        style={{
                          backgroundColor: '#' + item.colorHexCode
                        }}
                        data-tooltip={item.description}
                      >
                        <label className="tooltipBubble">{ item.description }</label>
                      </div>
                    </div>
                  )
                }
              </div>

              {
                backDesignAreaKeys.length
                  ? backDesignAreaKeys.map((designKey: any, orderIndex: number) =>
                      <div
                        key={'designKeyslideContent__show' + designKey + orderIndex}
                        className={'colorWrapper colorWrapper--7cols mt-4' + (showMoreColorSection[designKey] ? ' grid' : ' hidden')}
                      >
                        {
                          uniqueColorList.map((item, index) =>
                            <div
                              key={designKey + index + '#' + item.colorHexCode + item.description}
                              className={
                                "itemColor "
                                + ('#' + item.colorHexCode === selectedJerseySectionColor[designKey] ? 'active' : '')
                              }
                              onClick={() => onSelectColor(designKey, '#' + item.colorHexCode)}
                            >
                              <div
                                className={
                                  "itemColor--bgColor tooltip"
                                  + (item.code === '01_SU_WEISS' ? ' border' : '')
                                }
                                style={{
                                  backgroundColor: '#' + item.colorHexCode
                                }}
                                data-tooltip={item.description}
                              >
                                <label className="tooltipBubble">{ item.description }</label>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    )
                  : <></>
              }
            </div>

            <div className="slideContainer">
              <p className="slideContainer__headline marginBottom--xsmall">
                { t('configurator_3d_collar') } / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Collar]).description }
              </p>
              <div className="colorWrapper colorWrapper--7cols">
                <div
                  className={
                    "relative itemColor !pb-8 !h-auto "
                    + (showMoreColorSection[JerseySection.Collar] ? 'active' : '')
                  }
                  onClick={() => handleOnSelectOption(JerseySection.Collar)}
                >
                  <div
                    className={
                      "itemColor--bgColor tooltip"
                      + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Collar]).code === '01_SU_WEISS' ? ' border' : '')
                    }
                    style={{
                      backgroundColor: selectedJerseySectionColor[JerseySection.Collar]
                    }}
                    data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Collar]).description}
                  >
                    <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Collar]).description}</label>
                  </div>
                  <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_collar') }</p>
                </div>
              </div>

              <div
                className={
                  "colorWrapper colorWrapper--7cols mt-4"
                  + (showMoreColorSection[JerseySection.Collar] ? ' grid' : ' hidden')
                }
              >
                {
                  uniqueColorList.map((item, index) =>
                    <div
                      key={'collarColor' + index + '#' + item.colorHexCode + item.description}
                      className={
                        "itemColor "
                        + ('#' + item.colorHexCode === selectedJerseySectionColor[JerseySection.Collar] ? 'active' : '')
                      }
                      onClick={() => onSelectColor(JerseySection.Collar, '#' + item.colorHexCode)}
                    >
                      <div
                        className={
                          "itemColor--bgColor tooltip"
                          + (item.code === '01_SU_WEISS' ? ' border' : '')
                        }
                        style={{
                          backgroundColor: '#' + item.colorHexCode
                        }}
                        data-tooltip={item.description}
                      >
                        <label className="tooltipBubble">{ item.description }</label>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>

            <div className="slideContainer">
              <p className="slideContainer__headline marginBottom--xsmall">
                {
                  t('configurator_3d_sleeve')
                  + ' / '
                  + getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Left]).description
                  + ' / '
                  + getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Right]).description
                  + (sectionColorOptions && sectionColorOptions.cuff_left && sectionColorOptions.cuff_right ? ' / ' + getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.CuffLeft]).description + ' / ' + getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.CuffRight]).description : '')
                  + (sectionColorOptions && sectionColorOptions.elbow_left && sectionColorOptions.elbow_right ? ' / ' + getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elbow_left']).description + ' / ' + getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elbow_right']).description : '')
                }
              </p>
              <div className="colorWrapper colorWrapper--7cols">
                <div
                  className={
                    "relative itemColor !pb-8 !h-auto "
                    + (showMoreColorSection[JerseySection.Left] ? 'active' : '')
                  }
                  onClick={() => handleOnSelectOption(JerseySection.Left)}
                >
                  <div
                    className={
                      "itemColor--bgColor tooltip"
                      + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Left]).code === '01_SU_WEISS' ? ' border' : '')
                    }
                    style={{
                      backgroundColor: selectedJerseySectionColor[JerseySection.Left]
                    }}
                    data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Left]).description}
                  >
                    <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Left]).description}</label>
                  </div>
                  <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_sleeve_left') }</p>
                </div>
                <div
                  className={
                    "relative itemColor !pb-8 !h-auto "
                    + (showMoreColorSection[JerseySection.Right] ? 'active' : '')
                  }
                  onClick={() => handleOnSelectOption(JerseySection.Right)}
                >
                  <div
                    className={
                      "itemColor--bgColor tooltip"
                      + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Right]).code === '01_SU_WEISS' ? ' border' : '')
                    }
                    style={{
                      backgroundColor: selectedJerseySectionColor[JerseySection.Right]
                    }}
                    data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Right]).description}
                  >
                    <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Right]).description}</label>
                  </div>
                  <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_sleeve_right') }</p>
                </div>

                {
                  sectionColorOptions && sectionColorOptions.cuff_left
                    ? <div
                      className={
                        "relative itemColor !pb-8 !h-auto "
                        + (showMoreColorSection[JerseySection.CuffLeft] ? 'active' : '')
                      }
                      onClick={() => handleOnSelectOption(JerseySection.CuffLeft)}
                    >
                      <div
                        className={
                          "itemColor--bgColor tooltip"
                          + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.CuffLeft]).code === '01_SU_WEISS' ? ' border' : '')
                        }
                        style={{
                          backgroundColor: selectedJerseySectionColor[JerseySection.CuffLeft]
                        }}
                        data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.CuffLeft]).description}
                      >
                        <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.CuffLeft]).description}</label>
                      </div>
                      <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_cuff_left') }</p>
                    </div>
                    : <></>
                }
                {
                  sectionColorOptions && sectionColorOptions.cuff_right
                    ? <div
                      className={
                        "relative itemColor !pb-8 !h-auto "
                        + (showMoreColorSection[JerseySection.CuffRight] ? 'active' : '')
                      }
                      onClick={() => handleOnSelectOption(JerseySection.CuffRight)}
                    >
                      <div
                        className={
                          "itemColor--bgColor tooltip"
                          + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.CuffRight]).code === '01_SU_WEISS' ? ' border' : '')
                        }
                        style={{
                          backgroundColor: selectedJerseySectionColor[JerseySection.CuffRight]
                        }}
                        data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.CuffRight]).description}
                      >
                        <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.CuffRight]).description}</label>
                      </div>
                      <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_cuff_right') }</p>
                    </div>
                    : <></>
                }
                {
                  sectionColorOptions && sectionColorOptions.elbow_left
                    ? <div
                      className={
                        "relative itemColor !pb-8 !h-auto "
                        + (showMoreColorSection['elbow_left'] ? 'active' : '')
                      }
                      onClick={() => handleOnSelectOption('elbow_left')}
                    >
                      <div
                        className={
                          "itemColor--bgColor tooltip"
                          + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elbow_left']).code === '01_SU_WEISS' ? ' border' : '')
                        }
                        style={{
                          backgroundColor: selectedJerseySectionColor['elbow_left']
                        }}
                        data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elbow_left']).description}
                      >
                        <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elbow_left']).description}</label>
                      </div>
                      <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_elbow_left') }</p>
                    </div>
                    : <></>
                }
                {
                  sectionColorOptions && sectionColorOptions.elbow_right
                    ? <div
                      className={
                        "relative itemColor !pb-8 !h-auto "
                        + (showMoreColorSection['elbow_right'] ? 'active' : '')
                      }
                      onClick={() => handleOnSelectOption('elbow_right')}
                    >
                      <div
                        className={
                          "itemColor--bgColor tooltip"
                          + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elbow_right']).code === '01_SU_WEISS' ? ' border' : '')
                        }
                        style={{
                          backgroundColor: selectedJerseySectionColor['elbow_right']
                        }}
                        data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elbow_right']).description}
                      >
                        <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elbow_right']).description}</label>
                      </div>
                      <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_elbow_right') }</p>
                    </div>
                    : <></>
                }
              </div>

              <div
                className={
                  "colorWrapper colorWrapper--7cols mt-4"
                  + (showMoreColorSection[JerseySection.Left] ? ' grid' : ' hidden')
                }
              >
                {
                  uniqueColorList.map((item, index) =>
                    <div
                      key={'LeftColor' + index + '#' + item.colorHexCode + item.description}
                      className={
                        "itemColor "
                        + ('#' + item.colorHexCode === selectedJerseySectionColor[JerseySection.Left] ? 'active' : '')
                      }
                      onClick={() => onSelectColor(JerseySection.Left, '#' + item.colorHexCode)}
                    >
                      <div
                        className={
                          "itemColor--bgColor tooltip"
                          + (item.code === '01_SU_WEISS' ? ' border' : '')
                        }
                        style={{
                          backgroundColor: '#' + item.colorHexCode
                        }}
                        data-tooltip={item.description}
                      >
                        <label className="tooltipBubble">{ item.description }</label>
                      </div>
                    </div>
                  )
                }
              </div>
              <div
                className={
                  "colorWrapper colorWrapper--7cols mt-4"
                  + (showMoreColorSection[JerseySection.Right] ? ' grid' : ' hidden')
                }
              >
                {
                  uniqueColorList.map((item, index) =>
                    <div
                      key={'RightColor' + index + '#' + item.colorHexCode + item.description}
                      className={
                        "itemColor "
                        + ('#' + item.colorHexCode === selectedJerseySectionColor[JerseySection.Right] ? 'active' : '')
                      }
                      onClick={() => onSelectColor(JerseySection.Right, '#' + item.colorHexCode)}
                    >
                      <div
                        className={
                          "itemColor--bgColor tooltip"
                          + (item.code === '01_SU_WEISS' ? ' border' : '')
                        }
                        style={{
                          backgroundColor: '#' + item.colorHexCode
                        }}
                        data-tooltip={item.description}
                      >
                        <label className="tooltipBubble">{ item.description }</label>
                      </div>
                    </div>
                  )
                }
              </div>
              {
                sectionColorOptions && sectionColorOptions.cuff_left
                  ? <div
                      className={
                        "colorWrapper colorWrapper--7cols mt-4"
                        + (showMoreColorSection[JerseySection.CuffLeft] ? ' grid' : ' hidden')
                      }
                    >
                      {
                        uniqueColorList.map((item, index) =>
                          <div
                            key={'CuffLeftColor' + index + '#' + item.colorHexCode + item.description}
                            className={
                              "itemColor "
                              + ('#' + item.colorHexCode === selectedJerseySectionColor[JerseySection.CuffLeft] ? 'active' : '')
                            }
                            onClick={() => onSelectColor(JerseySection.CuffLeft, '#' + item.colorHexCode)}
                          >
                            <div
                              className={
                                "itemColor--bgColor tooltip"
                                + (item.code === '01_SU_WEISS' ? ' border' : '')
                              }
                              style={{
                                backgroundColor: '#' + item.colorHexCode
                              }}
                              data-tooltip={item.description}
                            >
                              <label className="tooltipBubble">{ item.description }</label>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  : <></>
              }
              {
                sectionColorOptions && sectionColorOptions.cuff_right
                  ? <div
                      className={
                        "colorWrapper colorWrapper--7cols mt-4"
                        + (showMoreColorSection[JerseySection.CuffRight] ? ' grid' : ' hidden')
                      }
                    >
                      {
                        uniqueColorList.map((item, index) =>
                          <div
                            key={'CuffRightColor' + index + '#' + item.colorHexCode + item.description}
                            className={
                              "itemColor "
                              + ('#' + item.colorHexCode === selectedJerseySectionColor[JerseySection.CuffRight] ? 'active' : '')
                            }
                            onClick={() => onSelectColor(JerseySection.CuffRight, '#' + item.colorHexCode)}
                          >
                            <div
                              className={
                                "itemColor--bgColor tooltip"
                                + (item.code === '01_SU_WEISS' ? ' border' : '')
                              }
                              style={{
                                backgroundColor: '#' + item.colorHexCode
                              }}
                              data-tooltip={item.description}
                            >
                              <label className="tooltipBubble">{ item.description }</label>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  : <></>
              }
              {
                sectionColorOptions && sectionColorOptions.elbow_left
                  ? <div
                      className={
                        "colorWrapper colorWrapper--7cols mt-4"
                        + (showMoreColorSection['elbow_left'] ? ' grid' : ' hidden')
                      }
                    >
                      {
                        uniqueColorList.map((item, index) =>
                          <div
                            key={'CuffRightColor' + index + '#' + item.colorHexCode + item.description}
                            className={
                              "itemColor "
                              + ('#' + item.colorHexCode === selectedJerseySectionColor['elbow_left'] ? 'active' : '')
                            }
                            onClick={() => onSelectColor('elbow_left', '#' + item.colorHexCode)}
                          >
                            <div
                              className={
                                "itemColor--bgColor tooltip"
                                + (item.code === '01_SU_WEISS' ? ' border' : '')
                              }
                              style={{
                                backgroundColor: '#' + item.colorHexCode
                              }}
                              data-tooltip={item.description}
                            >
                              <label className="tooltipBubble">{ item.description }</label>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  : <></>
              }
              {
                sectionColorOptions && sectionColorOptions.elbow_right
                  ? <div
                      className={
                        "colorWrapper colorWrapper--7cols mt-4"
                        + (showMoreColorSection['elbow_right'] ? ' grid' : ' hidden')
                      }
                    >
                      {
                        uniqueColorList.map((item, index) =>
                          <div
                            key={'CuffRightColor' + index + '#' + item.colorHexCode + item.description}
                            className={
                              "itemColor "
                              + ('#' + item.colorHexCode === selectedJerseySectionColor['elbow_right'] ? 'active' : '')
                            }
                            onClick={() => onSelectColor('elbow_right', '#' + item.colorHexCode)}
                          >
                            <div
                              className={
                                "itemColor--bgColor tooltip"
                                + (item.code === '01_SU_WEISS' ? ' border' : '')
                              }
                              style={{
                                backgroundColor: '#' + item.colorHexCode
                              }}
                              data-tooltip={item.description}
                            >
                              <label className="tooltipBubble">{ item.description }</label>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  : <></>
              }
            </div>
          </div>
        </div>

        <div className="flex justify-between mt-20">
          <button
            className="px-[27px] py-[12px] bg-[#FAFAFA] text-[#5B5B5B]"
            onClick={setSelectTabBack}
          >
            <div className="flex items-center">
              <FontAwesomeIcon
                className="h-[14px] mr-2"
                icon={faAngleLeft}
              />
              <span className='relative top-[-1px]'>{ t('configurator_3d_back') }</span>
            </div>
          </button>
          <button
            className="px-[27px] py-[12px] bg-[#008AC9] text-[#FFFFFF]"
            onClick={setSelectTabNext}
          >
            <div className="flex items-center">
              <span className='relative top-[-1px]'>{ t('configurator_3d_basket_next') }</span>
              <FontAwesomeIcon
                className="h-[14px] ml-2"
                icon={faAngleRight}
              />
            </div>
          </button>
        </div>
      </>
    : isMobile
      ? <div
        className="relative w-full px-6 pt-10 pb-6"
        style={{
          height: hasShowingMoreColorSection ? '325px' : '150px'
        }}
      >
        <button
          className="absolute top-[21px] right-[7px] z-10"
          onClick={handleOnClickCloseMobile}
        >
          <IconCross className="h-[26px]" />
        </button>
        <div className="relative w-full pb-3 flex items-center gap-5 items-center mb-4 before:absolute before:w-full before:h-[2px] before:bottom-[1px] before:left-0 before:bg-[#F0F0F0]">
          <button
            className={
              "relative font-semibold text-[14px] before:absolute before:w-full before:h-[2px] before:bg-[#141414] before:left-0 before:bottom-[-6px] "
              + (selectTabMobile === SelectColorTabMobile.Short ? 'text-[#141414] before:opacity-100' : 'text-[#C3C3C3] before:opacity-0')
            }
            onClick={() => onSelectTabMobile(SelectColorTabMobile.Short)}
          >
            { t('configurator_3d_short') }
          </button>
          <button
            className={
              "relative font-semibold text-[14px] before:absolute before:w-full before:h-[2px] before:bg-[#141414] before:left-0 before:bottom-[-6px] "
              + (selectTabMobile === SelectColorTabMobile.ShortStripe ? 'text-[#141414] before:opacity-100' : 'text-[#C3C3C3] before:opacity-0')
            }
            onClick={() => onSelectTabMobile(SelectColorTabMobile.ShortStripe)}
          >
            { t('configurator_3d_short_stripe') }
          </button>
        </div>
        <SimpleBar
          ref={scrollBarRef}
          style={{
            width: '100%',
            height: hasShowingMoreColorSection ? '262px' : '130px'
          }}
          autoHide={false}
          onScroll={onScrollContainerUpdate}
        >
          {
            selectTabMobile === SelectColorTabMobile.Short
              ? <div className="flex flex-col min-h-full mb-[10px]">
                <div className="flex justify-between">
                  <p className="text-[14px] pt-3">{ t('configurator_3d_choose_colors') }</p>
                  <div className="colorWrapper colorWrapper--flex-responsive">
                    <div
                      className={
                        "relative itemColor !pb-8 !h-auto "
                        + (showMoreColorSection[JerseySection.Right] ? 'active' : '')
                      }
                      onClick={() => handleOnSelectOption(JerseySection.Right)}
                    >
                      <div
                        className={
                          "itemColor--bgColor tooltip"
                          + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Right]).code === '01_SU_WEISS' ? ' border' : '')
                        }
                        style={{
                          backgroundColor: selectedJerseySectionColor[JerseySection.Right]
                        }}
                        data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Right]).description}
                      >
                        <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Right]).description }</label>
                      </div>
                      <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_sleeve_right') }</p>
                    </div>
                    <div
                      className={
                        "relative itemColor !pb-8 !h-auto "
                        + (showMoreColorSection[JerseySection.Left] ? 'active' : '')
                      }
                      onClick={() => handleOnSelectOption(JerseySection.Left)}
                    >
                      <div
                        className={
                          "itemColor--bgColor tooltip"
                          + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Left]).code === '01_SU_WEISS' ? ' border' : '')
                        }
                        style={{
                          backgroundColor: selectedJerseySectionColor[JerseySection.Left]
                        }}
                        data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Left]).description}
                      >
                        <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Left]).description }</label>
                      </div>
                      <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_sleeve_left') }</p>
                    </div>
                    {
                      selectedJerseySectionColor && selectedJerseySectionColor['elastic_main']
                        ? <div
                          className={
                            "relative itemColor !pb-8 !h-auto "
                            + (showMoreColorSection[JerseySection.ShortElastic] ? 'active' : '')
                          }
                          onClick={() => handleOnSelectOption(JerseySection.ShortElastic)}
                        >
                          <div
                            className={
                              "itemColor--bgColor tooltip"
                              + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elastic_main']).code === '01_SU_WEISS' ? ' border' : '')
                            }
                            style={{
                              backgroundColor: selectedJerseySectionColor['elastic_main']
                            }}
                            data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elastic_main']).description}
                          >
                            <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elastic_left_main']).description }</label>
                          </div>
                          <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_elastic') }</p>
                        </div>
                        : selectedJerseySectionColor && selectedJerseySectionColor['elastic_left_main']
                          ? <div
                            className={
                              "relative itemColor !pb-8 !h-auto "
                              + (showMoreColorSection[JerseySection.ShortElastic] ? 'active' : '')
                            }
                            onClick={() => handleOnSelectOption(JerseySection.ShortElastic)}
                          >
                            <div
                              className={
                                "itemColor--bgColor tooltip"
                                + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elastic_left_main']).code === '01_SU_WEISS' ? ' border' : '')
                              }
                              style={{
                                backgroundColor: selectedJerseySectionColor['elastic_left_main']
                              }}
                              data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elastic_left_main']).description}
                            >
                              <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elastic_left_main']).description }</label>
                            </div>
                            <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_elastic') }</p>
                          </div>
                          : <></>
                    }
                  </div>
                </div>
                <div
                  className={
                    "colorWrapper colorWrapper--responsive mt-4 pt-4 border-t-2 border-[#F0F0F0]"
                    + (showMoreColorSection[JerseySection.Right] ? ' grid' : ' hidden')
                  }
                >
                  {
                    uniqueColorList.map((item, index) =>
                      <div
                        key={'frontColor' + index + '#' + item.colorHexCode + item.description}
                        className={
                          "itemColor "
                          + ('#' + item.colorHexCode === selectedJerseySectionColor[JerseySection.Right] ? 'active' : '')
                        }
                        onClick={() => onSelectColor(JerseySection.Right, '#' + item.colorHexCode)}
                      >
                        <div
                          className={
                            "itemColor--bgColor tooltip"
                            + (item.code === '01_SU_WEISS' ? ' border' : '')
                          }
                          style={{
                            backgroundColor: '#' + item.colorHexCode
                          }}
                          data-tooltip={item.description}
                        >
                          <label className="tooltipBubble">{ item.description }</label>
                        </div>
                      </div>
                    )
                  }
                </div>
                <div
                  className={
                    "colorWrapper colorWrapper--responsive mt-4 pt-4 border-t-2 border-[#F0F0F0]"
                    + (showMoreColorSection[JerseySection.Left] ? ' grid' : ' hidden')
                  }
                >
                  {
                    uniqueColorList.map((item, index) =>
                      <div
                        key={'frontColor' + index + '#' + item.colorHexCode + item.description}
                        className={
                          "itemColor "
                          + ('#' + item.colorHexCode === selectedJerseySectionColor[JerseySection.Left] ? 'active' : '')
                        }
                        onClick={() => onSelectColor(JerseySection.Left, '#' + item.colorHexCode)}
                      >
                        <div
                          className={
                            "itemColor--bgColor tooltip"
                            + (item.code === '01_SU_WEISS' ? ' border' : '')
                          }
                          style={{
                            backgroundColor: '#' + item.colorHexCode
                          }}
                          data-tooltip={item.description}
                        >
                          <label className="tooltipBubble">{ item.description }</label>
                        </div>
                      </div>
                    )
                  }
                </div>
                {
                  selectedJerseySectionColor && selectedJerseySectionColor['elastic_main']
                    ? <div
                      className={
                        "colorWrapper colorWrapper--responsive mt-4 pt-4 border-t-2 border-[#F0F0F0]"
                        + (showMoreColorSection[JerseySection.ShortElastic] ? ' grid' : ' hidden')
                      }
                    >
                      {
                        uniqueColorList.map((item, index) =>
                          <div
                            key={'frontColor' + index + '#' + item.colorHexCode + item.description}
                            className={
                              "itemColor "
                              + ('#' + item.colorHexCode === selectedJerseySectionColor['elastic_main'] ? 'active' : '')
                            }
                            onClick={() => onSelectColor(JerseySection.ShortElastic, '#' + item.colorHexCode)}
                          >
                            <div
                              className={
                                "itemColor--bgColor tooltip"
                                + (item.code === '01_SU_WEISS' ? ' border' : '')
                              }
                              style={{
                                backgroundColor: '#' + item.colorHexCode
                              }}
                              data-tooltip={item.description}
                            >
                              <label className="tooltipBubble">{ item.description }</label>
                            </div>
                          </div>
                        )
                      }
                    </div>
                    : selectedJerseySectionColor && selectedJerseySectionColor['elastic_left_main']
                      ? <div
                        className={
                          "colorWrapper colorWrapper--responsive mt-4 pt-4 border-t-2 border-[#F0F0F0]"
                          + (showMoreColorSection[JerseySection.ShortElastic] ? ' grid' : ' hidden')
                        }
                      >
                        {
                          uniqueColorList.map((item, index) =>
                            <div
                              key={'frontColor' + index + '#' + item.colorHexCode + item.description}
                              className={
                                "itemColor "
                                + ('#' + item.colorHexCode === selectedJerseySectionColor['elastic_left_main'] ? 'active' : '')
                              }
                              onClick={() => onSelectColor(JerseySection.ShortElastic, '#' + item.colorHexCode)}
                            >
                              <div
                                className={
                                  "itemColor--bgColor tooltip"
                                  + (item.code === '01_SU_WEISS' ? ' border' : '')
                                }
                                style={{
                                  backgroundColor: '#' + item.colorHexCode
                                }}
                                data-tooltip={item.description}
                              >
                                <label className="tooltipBubble">{ item.description }</label>
                              </div>
                            </div>
                          )
                        }
                      </div>
                      : <></>
                }
              </div>
              : <></>
          }

          {
            selectTabMobile === SelectColorTabMobile.ShortStripe
              ? <div className="flex flex-col min-h-full mb-[10px]">
                <div className="flex justify-between">
                  <p className="text-[14px] pt-3">{ t('configurator_3d_choose_colors') }</p>
                  <div className="colorWrapper colorWrapper--flex-responsive">
                    <div
                      className={
                        "relative itemColor !pb-8 !h-auto "
                        + (showMoreColorSection[JerseySection.ShortStripe] ? 'active' : '')
                      }
                      onClick={() => handleOnSelectOption(JerseySection.ShortStripe)}
                    >
                      <div
                        className={
                          "itemColor--bgColor tooltip"
                          + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor['stripe_left']).code === '01_SU_WEISS' ? ' border' : '')
                        }
                        style={{
                          backgroundColor: selectedJerseySectionColor['stripe_left']
                        }}
                        data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor['stripe_left']).description}
                      >
                        <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor['stripe_left']).description }</label>
                      </div>
                      <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_short_stripe') }</p>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    "colorWrapper colorWrapper--responsive mt-4 pt-4 border-t-2 border-[#F0F0F0]"
                    + (showMoreColorSection[JerseySection.ShortStripe] ? ' grid' : ' hidden')
                  }
                >
                  {
                    uniqueColorList.map((item, index) =>
                      <div
                        key={'frontColor' + index + '#' + item.colorHexCode + item.description}
                        className={
                          "itemColor "
                          + ('#' + item.colorHexCode === selectedJerseySectionColor['stripe_left'] ? 'active' : '')
                        }
                        onClick={() => onSelectColor(JerseySection.ShortStripe, '#' + item.colorHexCode)}
                      >
                        <div
                          className={
                            "itemColor--bgColor tooltip"
                            + (item.code === '01_SU_WEISS' ? ' border' : '')
                          }
                          style={{
                            backgroundColor: '#' + item.colorHexCode
                          }}
                          data-tooltip={item.description}
                        >
                          <label className="tooltipBubble">{ item.description }</label>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
              : <></>
          }
        </SimpleBar>
        {
          hasMoreScrollContainer
            ? <div
              className={
                "absolute w-full h-[40px] bottom-[-1px] left-0 bg-gradient-to-b from-transparent to-white transition-all duration-200"
                + (hasMoreScrollContainer ? ' opacity-100' : ' opacity-0')
              }
            />
            : <></>
        }
      </div>
      : <>
        <div className="h1 text-center">{ t('configurator_3d_choose_colors') }</div>
        <div className="text-center marginBottom--small">{ t('configurator_3d_choose_colors_text') }</div>
        <div className="color-tab slideContent">
          <div className="py-4">
            <div className="slideContainer">
              {
                selectedJerseySectionColor && selectedJerseySectionColor['elastic_main']
                  ? <p className="slideContainer__headline marginBottom--xsmall">
                    { t('configurator_3d_short') } / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Left]).description } / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Right]).description } / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elastic_main']).description }
                  </p>
                  : selectedJerseySectionColor && selectedJerseySectionColor['elastic_left_main']
                    ? <p className="slideContainer__headline marginBottom--xsmall">
                      { t('configurator_3d_short') } / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Left]).description } / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Right]).description } / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elastic_left_main']).description }
                    </p>
                    : <></>
              }
              <div className="colorWrapper colorWrapper--7cols">
                <div
                  className={
                    "relative itemColor !pb-8 !h-auto "
                    + (showMoreColorSection[JerseySection.Left] ? 'active' : '')
                  }
                  onClick={() => handleOnSelectOption(JerseySection.Left)}
                >
                  <div
                    className={
                      "itemColor--bgColor tooltip"
                      + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Left]).code === '01_SU_WEISS' ? ' border' : '')
                    }
                    style={{
                      backgroundColor: selectedJerseySectionColor[JerseySection.Left]
                    }}
                    data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Left]).description}
                  >
                    <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Left]).description}</label>
                  </div>
                  <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_sleeve_left') }</p>
                </div>
                <div
                  className={
                    "relative itemColor !pb-8 !h-auto "
                    + (showMoreColorSection[JerseySection.Right] ? 'active' : '')
                  }
                  onClick={() => handleOnSelectOption(JerseySection.Right)}
                >
                  <div
                    className={
                      "itemColor--bgColor tooltip"
                      + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Right]).code === '01_SU_WEISS' ? ' border' : '')
                    }
                    style={{
                      backgroundColor: selectedJerseySectionColor[JerseySection.Right]
                    }}
                    data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Right]).description}
                  >
                    <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor[JerseySection.Right]).description}</label>
                  </div>
                  <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_sleeve_right') }</p>
                </div>

                {
                  sectionColorOptions && sectionColorOptions.elastic_main
                    ? <div
                      className={
                        "relative itemColor !pb-8 !h-auto "
                        + (showMoreColorSection[JerseySection.ShortElastic] ? 'active' : '')
                      }
                      onClick={() => handleOnSelectOption(JerseySection.ShortElastic)}
                    >
                      <div
                        className={
                          "itemColor--bgColor tooltip"
                          + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elastic_main']).code === '01_SU_WEISS' ? ' border' : '')
                        }
                        style={{
                          backgroundColor: selectedJerseySectionColor['elastic_main']
                        }}
                        data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elastic_main']).description}
                      >
                        <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elastic_left_main']).description}</label>
                      </div>
                      <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_elastic') }</p>
                    </div>
                    : sectionColorOptions && sectionColorOptions.elastic_left_main
                      ? <div
                        className={
                          "relative itemColor !pb-8 !h-auto "
                          + (showMoreColorSection[JerseySection.ShortElastic] ? 'active' : '')
                        }
                        onClick={() => handleOnSelectOption(JerseySection.ShortElastic)}
                      >
                        <div
                          className={
                            "itemColor--bgColor tooltip"
                            + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elastic_left_main']).code === '01_SU_WEISS' ? ' border' : '')
                          }
                          style={{
                            backgroundColor: selectedJerseySectionColor['elastic_left_main']
                          }}
                          data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elastic_left_main']).description}
                        >
                          <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor['elastic_left_main']).description}</label>
                        </div>
                        <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_elastic') }</p>
                      </div>
                      : <></>
                }
              </div>

              <div
                className={
                  "colorWrapper colorWrapper--7cols mt-4"
                  + (showMoreColorSection[JerseySection.Left] ? ' grid' : ' hidden')
                }
              >
                {
                  uniqueColorList.map((item, index) =>
                    <div
                      key={'LeftColor' + index + '#' + item.colorHexCode + item.description}
                      className={
                        "itemColor "
                        + ('#' + item.colorHexCode === selectedJerseySectionColor[JerseySection.Left] ? 'active' : '')
                      }
                      onClick={() => onSelectColor(JerseySection.Left, '#' + item.colorHexCode)}
                    >
                      <div
                        className={
                          "itemColor--bgColor tooltip"
                          + (item.code === '01_SU_WEISS' ? ' border' : '')
                        }
                        style={{
                          backgroundColor: '#' + item.colorHexCode
                        }}
                        data-tooltip={item.description}
                      >
                        <label className="tooltipBubble">{ item.description }</label>
                      </div>
                    </div>
                  )
                }
              </div>
              <div
                className={
                  "colorWrapper colorWrapper--7cols mt-4"
                  + (showMoreColorSection[JerseySection.Right] ? ' grid' : ' hidden')
                }
              >
                {
                  uniqueColorList.map((item, index) =>
                    <div
                      key={'RightColor' + index + '#' + item.colorHexCode + item.description}
                      className={
                        "itemColor "
                        + ('#' + item.colorHexCode === selectedJerseySectionColor[JerseySection.Right] ? 'active' : '')
                      }
                      onClick={() => onSelectColor(JerseySection.Right, '#' + item.colorHexCode)}
                    >
                      <div
                        className={
                          "itemColor--bgColor tooltip"
                          + (item.code === '01_SU_WEISS' ? ' border' : '')
                        }
                        style={{
                          backgroundColor: '#' + item.colorHexCode
                        }}
                        data-tooltip={item.description}
                      >
                        <label className="tooltipBubble">{ item.description }</label>
                      </div>
                    </div>
                  )
                }
              </div>
              {
                sectionColorOptions && sectionColorOptions.elastic_main
                  ? <div
                      className={
                        "colorWrapper colorWrapper--7cols mt-4"
                        + (showMoreColorSection[JerseySection.ShortElastic] ? ' grid' : ' hidden')
                      }
                    >
                      {
                        uniqueColorList.map((item, index) =>
                          <div
                            key={'ShortElasticColor' + index + '#' + item.colorHexCode + item.description}
                            className={
                              "itemColor "
                              + ('#' + item.colorHexCode === selectedJerseySectionColor['elastic_main'] ? 'active' : '')
                            }
                            onClick={() => onSelectColor(JerseySection.ShortElastic, '#' + item.colorHexCode)}
                          >
                            <div
                              className={
                                "itemColor--bgColor tooltip"
                                + (item.code === '01_SU_WEISS' ? ' border' : '')
                              }
                              style={{
                                backgroundColor: '#' + item.colorHexCode
                              }}
                              data-tooltip={item.description}
                            >
                              <label className="tooltipBubble">{ item.description }</label>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  : sectionColorOptions && sectionColorOptions.elastic_left_main
                    ? <div
                        className={
                          "colorWrapper colorWrapper--7cols mt-4"
                          + (showMoreColorSection[JerseySection.ShortElastic] ? ' grid' : ' hidden')
                        }
                      >
                        {
                          uniqueColorList.map((item, index) =>
                            <div
                              key={'ShortElasticColor' + index + '#' + item.colorHexCode + item.description}
                              className={
                                "itemColor "
                                + ('#' + item.colorHexCode === selectedJerseySectionColor['elastic_left_main'] ? 'active' : '')
                              }
                              onClick={() => onSelectColor(JerseySection.ShortElastic, '#' + item.colorHexCode)}
                            >
                              <div
                                className={
                                  "itemColor--bgColor tooltip"
                                  + (item.code === '01_SU_WEISS' ? ' border' : '')
                                }
                                style={{
                                  backgroundColor: '#' + item.colorHexCode
                                }}
                                data-tooltip={item.description}
                              >
                                <label className="tooltipBubble">{ item.description }</label>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    : <></>
              }
            </div>
            {
              sectionColorOptions && sectionColorOptions.stripe_left
                ? <div className="slideContainer">
                  <p className="slideContainer__headline marginBottom--xsmall">
                    { t('configurator_3d_short_stripe') } / { getSelectedColorOptionItemFromHex(selectedJerseySectionColor['stripe_left']).description }
                  </p>
                  <div className="colorWrapper colorWrapper--7cols">
                    <div
                      className={
                        "relative itemColor !pb-8 !h-auto "
                        + (showMoreColorSection[JerseySection.ShortStripe] ? 'active' : '')
                      }
                      onClick={() => handleOnSelectOption(JerseySection.ShortStripe)}
                    >
                      <div
                        className={
                          "itemColor--bgColor tooltip"
                          + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor['stripe_left']).code === '01_SU_WEISS' ? ' border' : '')
                        }
                        style={{
                          backgroundColor: selectedJerseySectionColor['stripe_left']
                        }}
                        data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor['stripe_left']).description}
                      >
                        <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor['stripe_left']).description}</label>
                      </div>
                      <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_short_stripe') }</p>
                    </div>
                  </div>

                  <div
                    className={
                      "colorWrapper colorWrapper--7cols mt-4"
                      + (showMoreColorSection[JerseySection.ShortStripe] ? ' grid' : ' hidden')
                    }
                  >
                    {
                      uniqueColorList.map((item, index) =>
                        <div
                          key={'ShortStripeColor' + index + '#' + item.colorHexCode + item.description}
                          className={
                            "itemColor "
                            + ('#' + item.colorHexCode === selectedJerseySectionColor['stripe_left'] ? 'active' : '')
                          }
                          onClick={() => onSelectColor(JerseySection.ShortStripe, '#' + item.colorHexCode)}
                        >
                          <div
                            className={
                              "itemColor--bgColor tooltip"
                              + (item.code === '01_SU_WEISS' ? ' border' : '')
                            }
                            style={{
                              backgroundColor: '#' + item.colorHexCode
                            }}
                            data-tooltip={item.description}
                          >
                            <label className="tooltipBubble">{ item.description }</label>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
                : <></>
            }
            {
              getOrderedDesignAreaKeys.current.length
                ? <div className="slideContainer">
                  <p className="slideContainer__headline marginBottom--xsmall">
                    {
                      getOrderedDesignAreaKeys.current.length
                        ? getOrderedDesignAreaKeys.current.map((designKey: string) => '/ ' + getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).description)
                        : ''
                    }
                  </p>
                  <div className="colorWrapper colorWrapper--7cols">
                    {
                      getOrderedDesignAreaKeys.current.length
                        ? getOrderedDesignAreaKeys.current.map((designKey: any, index: number) =>
                          <div
                            key={'frontDesign' + index + designKey}
                            className={
                              "relative itemColor !pb-8 !h-auto "
                              + (showMoreColorSection[designKey] ? 'active' : '')
                            }
                            onClick={() => handleOnSelectOption(designKey)}
                          >
                            <div
                              className={
                                "itemColor--bgColor tooltip"
                                + (getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).code === '01_SU_WEISS' ? ' border' : '')
                              }
                              style={{
                                backgroundColor: selectedJerseySectionColor[designKey]
                              }}
                              data-tooltip={getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).description}
                            >
                              <label className="tooltipBubble">{ getSelectedColorOptionItemFromHex(selectedJerseySectionColor[designKey]).description }</label>
                            </div>
                            <p className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[10px] font-semibold whitespace-nowrap">{ t('configurator_3d_shirt_design_no') + ' ' + (index + 1) }</p>
                          </div>
                        )
                        : <></>
                    }
                  </div>

                  {
                    getOrderedDesignAreaKeys.current.length
                      ? getOrderedDesignAreaKeys.current.map((designKey: any, orderIndex: number) =>
                          <div
                            key={'designKeyslideContent__show' + designKey + orderIndex}
                            className={'colorWrapper colorWrapper--7cols mt-4' + (showMoreColorSection[designKey] ? ' grid' : ' hidden')}
                          >
                            {
                              uniqueColorList.map((item, index) =>
                                <div
                                  key={designKey + index + '#' + item.colorHexCode + item.description}
                                  className={
                                    "itemColor "
                                    + ('#' + item.colorHexCode === selectedJerseySectionColor[designKey] ? 'active' : '')
                                  }
                                  onClick={() => onSelectColor(designKey, '#' + item.colorHexCode)}
                                >
                                  <div
                                    className={
                                      "itemColor--bgColor tooltip"
                                      + (item.code === '01_SU_WEISS' ? ' border' : '')
                                    }
                                    style={{
                                      backgroundColor: '#' + item.colorHexCode
                                    }}
                                    data-tooltip={item.description}
                                  >
                                    <label className="tooltipBubble">{ item.description }</label>
                                  </div>
                                </div>
                              )
                            }
                          </div>
                        )
                      : <></>
                  }
                </div>
                : <></>
            }
          </div>
        </div>

        <div className="flex justify-between mt-20">
          <button
            className="px-[27px] py-[12px] bg-[#FAFAFA] text-[#5B5B5B]"
            onClick={setSelectTabBack}
          >
            <div className="flex items-center">
              <FontAwesomeIcon
                className="h-[14px] mr-2"
                icon={faAngleLeft}
              />
              <span className='relative top-[-1px]'>{ t('configurator_3d_back') }</span>
            </div>
          </button>
          <button
            className="px-[27px] py-[12px] bg-[#008AC9] text-[#FFFFFF]"
            onClick={setSelectTabNext}
          >
            <div className="flex items-center">
              <span className='relative top-[-1px]'>{ t('configurator_3d_basket_next') }</span>
              <FontAwesomeIcon
                className="h-[14px] ml-2"
                icon={faAngleRight}
              />
            </div>
          </button>
        </div>
      </>;
}