import React, { useState, useEffect, useRef, useCallback, forwardRef, useImperativeHandle } from 'react';
import {
  faArrowUp,
  faArrowDown
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type TCustomScrollbarFunctionHandler = {
  scrollTo: (id: string) => void,
}

const CustomScrollbar = forwardRef<TCustomScrollbarFunctionHandler, React.ComponentPropsWithoutRef<'div'>>(({
  children,
  className,
  ...props
}, ref) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const scrollTrackRef = useRef<HTMLDivElement>(null);
  const scrollThumbRef = useRef<HTMLDivElement>(null);
  const observer = useRef<ResizeObserver | null>(null);
  const mutationObserver = useRef<MutationObserver | null>(null);
  const [thumbHeight, setThumbHeight] = useState(20);
  const [scrollStartPosition, setScrollStartPosition] = useState<number | null>(
    null
  );
  const [initialScrollTop, setInitialScrollTop] = useState<number>(0);
  const [isDragging, setIsDragging] = useState(false);

  useImperativeHandle(ref, () => ({
    scrollTo
  }));

  function scrollTo(id: string) {
    const { current: trackCurrent } = scrollTrackRef;
    const { current: contentCurrent } = contentRef;
    const scrollToElement = document.getElementById(id) as any;
    if (trackCurrent && contentCurrent && scrollToElement) {
      setTimeout(() => {
        contentCurrent.scrollTo({
          top: (scrollToElement.offsetTop - contentCurrent.offsetTop) + 140,
          behavior: 'smooth',
        });
      }, 800);
    }
  }

  function handleResize(ref: HTMLDivElement, trackSize: number) {
    const { clientHeight, scrollHeight } = ref;
    setThumbHeight(Math.max((clientHeight / scrollHeight) * trackSize, 20));
  }

  function handleScrollButton(direction: 'up' | 'down') {
    const { current } = contentRef;
    if (current) {
      const scrollAmount = direction === 'down' ? 200 : -200;
      current.scrollBy({ top: scrollAmount, behavior: 'smooth' });
    }
  }

  const handleTrackClick = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      const { current: trackCurrent } = scrollTrackRef;
      const { current: contentCurrent } = contentRef;
      if (trackCurrent && contentCurrent) {
        const { clientY } = e;
        const target = e.target as HTMLDivElement;
        const rect = target.getBoundingClientRect();
        const trackTop = rect.top;
        const thumbOffset = -(thumbHeight / 2);
        const clickRatio =
          (clientY - trackTop + thumbOffset) / trackCurrent.clientHeight;
        const scrollAmount = Math.floor(
          clickRatio * contentCurrent.scrollHeight
        );
        contentCurrent.scrollTo({
          top: scrollAmount,
          behavior: 'smooth',
        });
      }
    },
    [thumbHeight]
  );

  const handleThumbPosition = useCallback(() => {
    if (
      !contentRef.current ||
      !scrollTrackRef.current ||
      !scrollThumbRef.current
    ) {
      return;
    }
    const { scrollTop: contentTop, scrollHeight: contentHeight } =
      contentRef.current;
    const { clientHeight: trackHeight } = scrollTrackRef.current;
    let newTop = (+contentTop / +contentHeight) * trackHeight;
    newTop = Math.min(newTop, trackHeight - thumbHeight);
    const thumb = scrollThumbRef.current;
    thumb.style.top = `${newTop}px`;
  }, [thumbHeight]);

  const handleThumbMousedown = useCallback((e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setScrollStartPosition(e.clientY);
    if (contentRef.current) setInitialScrollTop(contentRef.current.scrollTop);
    setIsDragging(true);
  }, []);

  const handleThumbMouseup = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (isDragging) {
        setIsDragging(false);
      }
    },
    [isDragging]
);

  const handleThumbMousemove = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (!contentRef.current) {
        return;
      }
      if (isDragging && scrollStartPosition) {
        const {
          scrollHeight: contentScrollHeight,
          offsetHeight: contentOffsetHeight,
        } = contentRef.current;

        const deltaY =
          (e.clientY - scrollStartPosition) *
          (contentOffsetHeight / thumbHeight);
        const newScrollTop = Math.min(
          initialScrollTop + deltaY,
          contentScrollHeight - contentOffsetHeight
        );

        if (contentRef.current) {
          contentRef.current.scrollTop = newScrollTop;
        }
      }
    },
    [isDragging, scrollStartPosition, thumbHeight, initialScrollTop]
  );

  // If the content and the scrollbar track exist, use a ResizeObserver to adjust height of thumb and listen for scroll event to move the thumb
  useEffect(() => {
    if (contentRef.current && scrollTrackRef.current) {
      const ref = contentRef.current;
      const { clientHeight: trackSize } = scrollTrackRef.current;
      observer.current = new ResizeObserver(function() {
        handleResize(ref, trackSize);
      });
      mutationObserver.current = new MutationObserver(function(muta) {
        setTimeout(() => {
          handleResize(ref, trackSize);
        }, 800);
      });
      setTimeout(() => {
        handleResize(ref, trackSize);
      }, 200);
      observer.current.observe(ref);
      mutationObserver.current.observe(ref, {attributes: true, childList: true, subtree: true});
      ref.addEventListener('scroll', handleThumbPosition);
      return () => {
        observer.current?.unobserve(ref);
        mutationObserver.current?.disconnect();
        ref.removeEventListener('scroll', handleThumbPosition);
      };
    }
  }, [handleThumbPosition]);


  // Listen for mouse events to handle scrolling by dragging the thumb
  useEffect(() => {
    document.addEventListener('mousemove', handleThumbMousemove);
    document.addEventListener('mouseup', handleThumbMouseup);
    document.addEventListener('mouseleave', handleThumbMouseup);
    return () => {
      document.removeEventListener('mousemove', handleThumbMousemove);
      document.removeEventListener('mouseup', handleThumbMouseup);
      document.removeEventListener('mouseleave', handleThumbMouseup);
    };
  }, [handleThumbMousemove, handleThumbMouseup]);

  return <div className={
    "custom-scrollbars__container"
    + (contentRef.current && contentRef.current.clientHeight >= contentRef.current.scrollHeight ? ' hide' : '')
  }>
    <div className={"custom-scrollbars__content " + className} ref={contentRef} {...props}>
      {children}
    </div>
    <div className={
      "custom-scrollbars__scrollbar"
      + (contentRef.current && contentRef.current.clientHeight >= contentRef.current.scrollHeight ? ' opacity-0' : '')
    }>
      <button
        className="custom-scrollbars__button pt-0.5"
        onClick={() => handleScrollButton('up')}
      >
        <FontAwesomeIcon icon={faArrowUp}/>
      </button>
      <div className="custom-scrollbars__track-and-thumb">
        <div
          className="custom-scrollbars__track"
          ref={scrollTrackRef}
          onClick={handleTrackClick}
          style={{ cursor: isDragging ? 'pointer' : 'grabbing' }}
          />
        <div
          className="custom-scrollbars__thumb"
          ref={scrollThumbRef}
          onMouseDown={handleThumbMousedown}
          style={{
            height: `${thumbHeight}px`,
            cursor: isDragging ? 'grabbing' : 'grab',
          }}
          />
      </div>
      <button
        className="custom-scrollbars__button pb-0.5"
        onClick={() => handleScrollButton('down')}
      >
        <FontAwesomeIcon icon={faArrowDown}/>
      </button>
    </div>
  </div>;
});

export default CustomScrollbar;
